import React, { useContext, useEffect } from "react";
import BannerOrganizationalTransformation from "./Body/BannerOrganizationalTransformation";
import { validationLan } from "../../../../ValidationLan/ValidationLan";
import currentLanguageContext from "../../../Context/Context";

const OrganizationalTransformation = () => {
  const lang = useContext(currentLanguageContext);
  useEffect(() => {
    validationLan();
    changeImage();
  }, [lang.lan]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const changeImage = () => {
    let ComponentORT_1IMG = document.getElementById("ComponentORT_1IMG");
    let OrganizationalTransformation_2IMG = document.getElementById(
      "OrganizationalTransformation_2IMG"
    );
    if (lang.lan) {
      ComponentORT_1IMG.src = "/assets/img/PortfolioPage/ComponentORT_1-Ar.png";
      OrganizationalTransformation_2IMG.src =
        "/assets/img/PortfolioPage/Organizational Transformation_2-Ar.png";
    } else {
      ComponentORT_1IMG.src = "/assets/img/PortfolioPage/ComponentORT_1.png";
      OrganizationalTransformation_2IMG.src =
        "/assets/img/PortfolioPage/Organizational Transformation_2.png";
    }
  };
  return <BannerOrganizationalTransformation />;
};

export default OrganizationalTransformation;
