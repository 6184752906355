import React from "react";
import {  Container } from "react-bootstrap";
import Header from "../../../BlogPage/Header/Header";
import stylesSTR from "../../../PortfolioPage/ValueProposition/StrategySystems/Body/BannerStrategySystems.module.css";
import RowText from "../../../PortfolioPage/ValueProposition/StrategySystems/Body/RowText";
import styles from "../../../YouPage/You.module.css";
import FormIntern from "./FormIntern";
const BannerIntern = () => {
  return (
    <section>
      <Container className={""} dir="">
        <Header
          text="Interns"
          classesText={`${stylesSTR.textHeader} ${styles.TitleHeader}`}
          classes={stylesSTR.textHeaderChild}
          nameVideo={"Header.mp4"}
        />
        <RowText
          classes={styles.textHeader}
          textArray={["textOneExpertPage"]}
        />
        <FormIntern/>
      </Container>
    </section>
  );
};

export default BannerIntern;
