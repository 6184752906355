import React, { useEffect } from "react";
import BannerTestimonials from "./Body/BannerTestimonials";
import { validationLan } from "../../../ValidationLan/ValidationLan";

const Testimonials = () => {
  let isArabic = localStorage.getItem("lan") === "AR";
  useEffect(() => {
    validationLan();
  }, [isArabic]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <BannerTestimonials />;
};

export default Testimonials;
