import React, { useContext, useEffect } from "react";
import BannerCapabilityDevelopment from "./Body/BannerCapabilityDevelopment";
import { validationLan } from "../../../../ValidationLan/ValidationLan";
import currentLanguageContext from "../../../Context/Context";

const CapabilityDevelopment = () => {
  const lang = useContext(currentLanguageContext);
  useEffect(() => {
    validationLan();
    changeImage();
  }, [lang.lan]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const changeImage = () => {
    let CapabilityDevelopment_1IMG = document.getElementById(
      "CapabilityDevelopment_1IMG"
    );
    if (lang.lan) {
      CapabilityDevelopment_1IMG.src =
        "/assets/img/PortfolioPage/CapabilityDevelopment_1-Ar.jpg";
    } else {
      CapabilityDevelopment_1IMG.src =
        "/assets/img/PortfolioPage/CapabilityDevelopment_1.png";
    }
  };
  return <BannerCapabilityDevelopment />;
};

export default CapabilityDevelopment;
