import React, { useEffect } from "react";
import BannerHome from "./Body/BannerHome";
import Organizational from "./Body/Organizational";
import Grow from "./Body/Grow";
import Services from "./Body/Services";
import Approach from "./Body/Approach";
import Clients from "./Body/Clients";
import { validationLan } from "../../ValidationLan/ValidationLan";
import OurServices from "./Body/OurServices";

const HomePage = () => {
  let isArabic = localStorage.getItem("lan") === "AR";
  useEffect(() => {
    validationLan();
  }, [isArabic]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <BannerHome />
      <Organizational />
      <Grow />
      <Services />
      <Approach />
      <OurServices />
      <Clients />
    </>
  );
};
export default HomePage;
