import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./BannerExpertisePromiseYou.module.css";
import HighlightedText from "../../../PortfolioPage/Customer Centricity/Body/HighlightedText";

const CardRightV = (props) => {
  const [tKey] = useTranslation();
  return (
    <Row
      xl={6}
      className={`justify-content-center row  ${
        props.isPortfolio ? props.classRow : styles.cardR
      }`}
    >
      <Col
        xl={7}
        className={`col-xl-7 col-md-7 col-xxl-7 col-auto  ${
          props.classtextContent ? props.classtextContent : styles.textContent
        }`}
      >
        {props.title ? (
          <h3 className={props.classTitle}>{tKey(props.title)}</h3>
        ) : null}

        <HighlightedText
          highlightedTextClasses={props.highlightedTextClasses}
          textArray={props.textArray}
        />
      </Col>
      <Col
        xl={2}
        className={`col-auto ${props.classImg ? props.classImg : styles.img}`}
      >
        {props.isPortfolio ? (
          <video
            className={`${props.classVid}`}
            autoPlay={true}
            loop={true}
            muted
          >
            <source src={`/assets/video/PortfolioPage/${props.nameVid}`} />
          </video>
        ) : (
          <img
            src={`${props.isPortfolio ? props.src : `/assets/img/UsPage/`}${
              props.nameImg
            }`}
            alt={"#Img"}
          />
        )}
      </Col>
    </Row>
  );
};
export default CardRightV;
