import React, { useEffect } from "react";
import BannerPartner from "./Body/BannerPartner";
import { validationLan } from "../../../ValidationLan/ValidationLan";

const Partner = () => {
  let isArabic = localStorage.getItem("lan") === "AR";
  useEffect(() => {
    validationLan();
  }, [isArabic]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <BannerPartner />;
};

export default Partner;
