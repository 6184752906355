import React, { useEffect } from "react";
import BannerExpertisePromiseYou from "./Body/BannerExpertisePromiseYou";
import { validationLan } from "../../../ValidationLan/ValidationLan";

const ExpertisePromiseYou = () => {
  let isArabic = localStorage.getItem("lan") === "AR";
  useEffect(() => {
    validationLan();
  }, [isArabic]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <BannerExpertisePromiseYou />
    </>
  );
};
export default ExpertisePromiseYou;
