import React from "react";
import "./IconText.css";
const IconText = (props) => {
  return (
    <div className="mb-2">
      <img
        className="m-1 img-size"
        src={`/assets/img/Footer/${props.imgName}`}
        alt={props.alt}
      />
      <span className="text-white text-uppercase px-2">{props.text}</span>
    </div>
  );
};
export default IconText;
