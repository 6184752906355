import React from "react";
import "./SiteScroll.css";

const SiteScroll = () => {
  return (
    <div className="scroll-container">
      <div className="mouse"></div>
      <p className="scroll-text">Scroll</p>
    </div>
  );
};
export default SiteScroll;
