import axios from 'axios';
import { useMemo } from 'react';
import { SERVER_URL } from '../../config';
const useAxiosClient = () => {
    const axiosInstance = useMemo(() => axios.create({
        baseURL: SERVER_URL,
        headers: {
            'Authorization': 'token ABCDE_EFGH:123456_654321',
            'Content-Type': 'multipart/form-data'
          }
    }), []);
    return axiosInstance;
};
export default useAxiosClient;