import { useTranslation } from "react-i18next";
import Getdata from "../../../Apis/Getdata";
const ProjectsData = (id) => {
    const [i18n] = useTranslation();
    const data = (!id)?Getdata("/projects"):Getdata("/project/"+id);
    let projectsArray = [];
    if(data.length>0 && !id) { 
        data.map((element, index) => {
            projectsArray[index] ={
                'cell_index':element.project_id,
                'cell_img':"../assets/img/YouPage/clients/"+element.client_id+".jpg",
                'cell_title':(i18n.language === "en") ? element.title_en :element.title_en,
                'cell_link':"../you/project/"+element.project_id,
                'cell_description':(i18n.language === "en") ? element.client_name_en:element.client_name_en
            }
        });
    }else projectsArray = data;
    return projectsArray;
}
export default ProjectsData;