import React, { useContext, useEffect } from "react";
import BannerPhilosophyValueTriangle from "./Body/BannerPhilosophyValueTriangle";
import { validationLan } from "../../../ValidationLan/ValidationLan";
import currentLanguageContext from "../../Context/Context";

const PhilosophyValueTriangle = () => {
  const lang = useContext(currentLanguageContext);

  useEffect(() => {
    validationLan();
    changeImage();
  }, [lang.lan]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const changeImage = () => {
    let productsServicesImg = document.getElementById("products-servicesImg");
    let triangle_1Img = document.getElementById("Triangle_1Img");
    let triangle_2Img = document.getElementById("Triangle_2Img");
    let triangle_3Img = document.getElementById("Triangle_3Img");
    let triangle_4Img = document.getElementById("Triangle_4Img");
    let triangle_5Img = document.getElementById("Triangle_5Img");
    if (lang.lan) {
      productsServicesImg.src = "/assets/img/UsPage/products & services-Ar.jpg";
      triangle_1Img.src = "/assets/img/UsPage/Triangle_1-Ar.jpg";
      triangle_2Img.src = "/assets/img/UsPage/Triangle_2-Ar.jpg";
      triangle_3Img.src = "/assets/img/UsPage/Triangle_3-Ar.jpg";
      triangle_4Img.src = "/assets/img/UsPage/Triangle_4-Ar.jpg";
      triangle_5Img.src = "/assets/img/UsPage/Triangle_5-Ar.jpg";
    } else {
      productsServicesImg.src = "/assets/img/UsPage/products & services.png";
      triangle_1Img.src = "/assets/img/UsPage/Triangle_1.png";
      triangle_2Img.src = "/assets/img/UsPage/Triangle_2.png";
      triangle_3Img.src = "/assets/img/UsPage/Triangle_3.png";
      triangle_4Img.src = "/assets/img/UsPage/Triangle_4.png";
      triangle_5Img.src = "/assets/img/UsPage/Triangle_5.png";
    }
  };
  return <BannerPhilosophyValueTriangle />;
};
export default PhilosophyValueTriangle;
