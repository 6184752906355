import React, { useEffect } from "react";
import Header from "../Components/Header/Header";
import ServicesContent from "../Components/Content/ServicesContent";
import { CapabilityDevelopmentData } from "./../Data/CapabilityDevelopmentData";

const ServicesCapabilityDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header
        img="ServicesHeader.png"
        icon="CapabilityDevelopmentHeader.png"
        text="Capability Development"
      />
      <ServicesContent dataArray={CapabilityDevelopmentData} />
    </>
  );
};

export default ServicesCapabilityDevelopment;
