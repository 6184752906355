import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "./../../Header/Header";
import ColInput from "../../Projects/Body/ColInput";
import stylesSTR from "../../../PortfolioPage/ValueProposition/StrategySystems/Body/BannerStrategySystems.module.css";
import styles from "../../You.module.css";
import Gridcell from "../../../Hexagon/Gridcell";
import ClientsData from "../Data/ClientsData";
const BannerClients = () => {
  const cells = ClientsData(0);
  return (
    <section>
      <Container className={""} dir="">
        <Header
          text="Clients"
          classesText={`${stylesSTR.textHeader} ${styles.TitleHeader}`}
          classes={stylesSTR.textHeaderChild}
          nameVideo={"Header.m4v"}
        />
        {
          cells.length > 0 
          ?<Row xl={12} className={` row justify-content-center`}>
          <ul id="hexGrid">{cells.map((cell)=> <Gridcell key={cell.cell_index} cell_attr ={cell} />)}</ul>
        </Row> 
          : ''
        }
      </Container>
    </section>
  );
};
export default BannerClients;
