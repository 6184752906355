import React, { useEffect } from "react";
import BannerClients from "./Body/BannerClients";
import { validationLan } from "../../../ValidationLan/ValidationLan";

const Clients = () => {
  let isArabic = localStorage.getItem("lan") === "AR";
  useEffect(() => {
    validationLan();
  }, [isArabic]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <BannerClients />;
};

export default Clients;
