import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import Header from "../../Header/Header";
import ColInput from "../../Projects/Body/ColInput";
import stylesSTR from "../../../PortfolioPage/ValueProposition/StrategySystems/Body/BannerStrategySystems.module.css";
import styles from "../../You.module.css";
import ClientsData from "../Data/ClientsData";
import { useTranslation } from "react-i18next";
const BannerClient = () => {
  const [tKey, i18n] = useTranslation();
  let { id } = useParams();
  console.log('the id is :'+id);
 let clientInfo = ClientsData(id);
  return (
    <section>
      <Container className={""} dir="">
        <Header
          text="Clients"
          classesText={`${stylesSTR.textHeader} ${styles.TitleHeader}`}
          classes={stylesSTR.textHeaderChild}
          nameVideo={"Header.m4v"}
        />
        <Row xl={6} className={` row justify-content-center `}>
          <ColInput
            xl={3}
            classInput={`${styles.input} ${styles.inputImgCL_1}`}
            placeholder={"sector"}
            value={(i18n.language === "en")?clientInfo.sector_name_en:clientInfo.sector_name_ar}
          />

          <ColInput
            xl={3}
            classInput={`${styles.input} ${styles.inputImgCL_2}`}
            placeholder={"nature"}
            value={(i18n.language === "en")?clientInfo.nature_name_en:clientInfo.nature_name_ar}
          />
          <ColInput
            xl={3}
            classInput={`${styles.input} ${styles.inputImgCL_3}`}
            placeholder={"domain"}
            value={(i18n.language === "en")?clientInfo.domain_name_en:clientInfo.domain_name_ar}
          />
        </Row>
        <Row xl={6} className={` row justify-content-center `}>
          <ColInput
            xl={9}
            classesCol={`${styles.colInput} col-md-9`}
            classInput={`${styles.input} ${styles.inputT} `}
            placeholder={"title"}
          />
        </Row>
        <Row xl={6} className={` row justify-content-center `}>
          <ColInput
            name={"LocClient"}
            xl={9}
            classesCol={`${styles.colInput} col-md-9`}
            classInput={`${styles.input} ${styles.inputT}  ${styles.inputImg_2} `}
            placeholder={"location"}
          />
        </Row>
        <Row xl={6} className={` row justify-content-center `}>
          <Col xl={9} className={`col-auto  col-md-9 ${styles.colInput}`}>
            <textarea
              className={styles.textArea}
              rows={11}
              placeholder={"description"}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BannerClient;
