import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, NavDropdown, Nav, Navbar } from "react-bootstrap";
import menuData from "./Data/data.json";
import currentLanguageContext from "./../Context/Context";
import "./NavBar.css";

const NavBar = () => {
  const lang = useContext(currentLanguageContext);
  const [classesDropdown, setClassesDropdown] = useState("");
  const [scrolled, setScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState("home");
  const [lan, setLan] = useState("");
  useEffect(() => {
    let elements = document.getElementsByTagName("*");
    if (lan === "AR") {
      i18n.changeLanguage("ar");
      setLan("EN");
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.fontFamily = "Bahij TheSansArabic,Cairo";
      }
      document.body.style.direction = "rtl";
    } else {
      i18n.changeLanguage("en");
      setLan("AR");
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.fontFamily = "Outfit";
      }
      document.body.style.direction = "ltr";
    }

    loadTranslation();
    const onScroll = () => {
      if (window.scrollY < 50) {
        setScrolled(false);
      } else {
        setScrolled(true);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  const onUpdateActiveLink = (url, classes) => {
    setActiveLink(url);
    setClassesDropdown(classes);
  };

  const [tKey, i18n] = useTranslation();

  const loadTranslation = () => {
    let elements = document.getElementsByTagName("*");
    let isArabic = localStorage.getItem("lan") === "AR";
    if (isArabic) {
      i18n.changeLanguage("ar");
      setLan("EN");
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.fontFamily = "Bahij TheSansArabic,Cairo";
        elements[i].style.letterspacing = "normal";
      }
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.letterSpacing = "normal";
      }

      document.body.style.direction = "rtl";
    } else {
      i18n.changeLanguage("en");
      setLan("AR");
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.fontFamily = "Outfit";
      }

      for (let i = 0; i < elements.length; i++) {
        elements[i].style.letterSpacing = "";
      }

      document.body.style.direction = "ltr";
    }
    lang.setLan(isArabic);
  };

  const MenuItem = ({ title, link, submenu }) => {
    if (submenu) {
      return i18n.language === "en" ? (
        <NavDropdown title={title} id="basic-nav-dropdown ">
          {submenu.map((item, index) => (
            <MenuItem
              key={index}
              title={item.title}
              link={item.link}
              submenu={item.submenu}
            />
          ))}
        </NavDropdown>
      ) : (
        <NavDropdown title={title} id="basic-nav-dropdown ">
          {submenu.map((item, index) => (
            <MenuItem
              key={index}
              title={item.titleAR}
              link={item.link}
              submenu={item.submenu}
            />
          ))}
        </NavDropdown>
      );
    } else {
      return (
        <Link className="text-decoration-none m-auto  link-light " to={link}>
          <Nav.Link
            href={link}
            className={`
                ${activeLink === link ? "active navbar-link" : "navbar-link"}`}
            onClick={() => {
              onUpdateActiveLink(link);
            }}
          >
            {" "}
            {tKey(title)}
          </Nav.Link>
        </Link>
      );
    }
  };
  return (
    <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
      <Container className={"navText"}>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Brand
          href="#"
          className={`
                        ${
                          activeLink === "Home"
                            ? "active navbar-link"
                            : "navbar-link"
                        }`}
          onClick={() => {
            onUpdateActiveLink("Home");
          }}
        >
          <Link to={"/"}>
            <img
              className="logo"
              src={"/assets/img/QuaimLogo.png"}
              alt="Logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="Nav-bar" dir="rtl">
            {i18n.language === "en"
              ? menuData.menu.map((item, index) => (
                  <MenuItem
                    key={index}
                    title={item.title}
                    link={item.link}
                    submenu={item.submenu}
                  />
                ))
              : menuData.menu.map((item, index) => (
                  <MenuItem
                    key={index}
                    title={item.titleAR}
                    link={item.link}
                    submenu={item.submenu}
                  />
                ))}
          </Nav>
          <span className="navbar-text ">
            <div className="social-icon d-flex">
              <button>{tKey("Login")}</button>
              <button
                className="btn-ln"
                id="lan"
                onClick={() => {
                  if (lan === "AR") {
                    localStorage.setItem("lan", "EN");
                    loadTranslation();
                  } else {
                    localStorage.setItem("lan", "EN");
                    loadTranslation();
                  }
                }}
              >
                {lan}{" "}
              </button>
            </div>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
