import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import CardImage from "./CardImage";
import { clients } from "../Data/Clients";
  import { useTranslation } from "react-i18next";
import styles from "./../../../Components/PortfolioPage/ValueProposition/StrategySystems/Body/BannerStrategySystems.module.css";
import "./Clients.css";

const Clients = () => {
  const Clients = clients.map((cli) => {
    return <CardImage key={cli.id} src={cli.src} />;
  });
  const [tKey] = useTranslation();
  return (
    <section className="clients">
      <Container dir="">
        <Row className="justify-content-center">
          <Col className="col-auto content-client">
            <p className="text-one">{tKey("OUR HAPPY CLIENTS")}</p>
            <p className="text-tow">
              {tKey(
                "FROM SMALL-MEDIUM ENTERPRISES AND ORGANIZATIONS (SMEs) TO LARGE SCALE OPERATIONS, WE PROVIDE OUR SERVICES TO ORGANIZATIONS AT ALL STAGES OF DEVELOPMENT SCALE OVER THE WORLD TO "
              )}
              <span className={styles.textColor}>
                {tKey("COME UP WITH THE BOLD IDEAS AND BRING THEM TO LIFE.")}
              </span>
            </p>
            <p className="text-tow">
              {tKey("BE A ")}
              <span className={styles.textColor}>
                {tKey("BUSINESS, A NON-PROFIT, OR A GOVERNMENTAL AGENCY, ")}
              </span>
              <span>
                {tKey(
                  "YOU HAVE ARRIVED AT THE RIGHT PLACE TO DEVELOP AND TRANSFORM YOUR ORGANIZATION IN THE DISCIPLINES OF "
                )}
              </span>
              <span className={styles.textColor}>
                {tKey(
                  "(BUSINESS, MANAGEMENT, AND CAPABILITY), CHECK OUR VALUE TRIANGLE FOR MORE INFORMATION."
                )}
              </span>
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center Clients">{Clients}</Row>
        <Row className="justify-content-center content-clients">
          <Col xl={5} className="col-auto content-clients-text">
            <p className="text-one">{tKey("YOUR END TO END")}</p>
            <p className="text-tow">
              {tKey(
                "ORGANIZATIONAL DEVELOPMENT & TRANSFORMATION (ODT) STARTS HERE"
              )}
            </p>
            <Link
              className="text-decoration-none m-auto link-light "
              to={"/contact-us"}
            >
            </Link>
          </Col>
          <Col xl={4} className="col-auto">
            <img
              src="/assets/img/HomePage/Clients/Vector Smart Object copy 6.png"
              alt="home"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Clients;
