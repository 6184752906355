import React from "react";
import { Col, Row } from "react-bootstrap";
import HighlightedText from "../../../Customer Centricity/Body/HighlightedText";
import styles from "./BannerStrategySystems.module.css";

const RowText = (props) => {
  return (
    <Row xl={6} className={` row justify-content-center ${props.classes} mb-5`}>
      <Col xl={10} className={`col-auto ${styles.text}`}>
        <HighlightedText
          highlightedTextClasses={props.highlightedTextClasses}
          textArray={props.textArray}
        />
      </Col>
    </Row>
  );
};
export default RowText;
