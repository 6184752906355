import { useAxios } from "use-axios-client";
import {SERVER_URL,API_KEY} from "../../config";
const GetData = (url) => {
  const { data, error, loading } = useAxios({
    url: SERVER_URL+url,
    headers: {
      'Authorization': 'token '+API_KEY
    }
  });
  
  if (loading || !data) return loading;
  if (error) return "error";
  return data.data;
};
export default GetData;
