import React, { useContext, useEffect } from "react";
import BannerStrategySystems from "./Body/BannerStrategySystems";
import { validationLan } from "../../../../ValidationLan/ValidationLan";
import currentLanguageContext from "../../../Context/Context";

const StrategySystems = () => {
  const lang = useContext(currentLanguageContext);
  useEffect(() => {
    validationLan();
    changeImage();
  }, [lang.lan]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const changeImage = () => {
    let StrategySystems_1Img = document.getElementById("StrategySystems_1Img");
    let Component1_1Img = document.getElementById("Component1_1Img");
    if (lang.lan) {
      StrategySystems_1Img.src =
        "/assets/img/PortfolioPage/StrategySystems_1-Ar.jpg";
      Component1_1Img.src = "/assets/img/PortfolioPage/Component 1 – 1-Ar.png";
    } else {
      StrategySystems_1Img.src =
        "/assets/img/PortfolioPage/StrategySystems_1.png";
      Component1_1Img.src = "/assets/img/PortfolioPage/Component 1 – 1.png";
    }
  };
  return <BannerStrategySystems />;
};

export default StrategySystems;
