import React, { useContext, useEffect } from "react";
import BannerHexastagesProcess from "./Body/BannerHexastagesProcess";
import { validationLan } from "../../../ValidationLan/ValidationLan";
import currentLanguageContext from "../../Context/Context";

const PhilosophyHexastagesProcess = () => {
  const lang = useContext(currentLanguageContext);
  useEffect(() => {
    validationLan();
    changeVid();
  }, [lang.lan]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const changeVid = () => {
    let HeagonVID = document.getElementById("HeagonVID");
    if (lang.lan) {
      HeagonVID.src = "/assets/video/USPage/Hexagon-Ar.mp4";
    } else {
      HeagonVID.src = "/assets/video/USPage/Heagon.mp4";
    }
  };
  return <BannerHexastagesProcess />;
};

export default PhilosophyHexastagesProcess;
