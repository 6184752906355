export const CapabilityDevelopmentData = [
  {
    subServiceIcon: "CapabilityDevelopment/CapabilityDevelopment1.svg",
    subServiceIconAlt: "Organizational Capacity/Capability Assessment (OCA)",
    subServiceName: "Organizational Capacity/Capability Assessment (OCA)",
    subServiceNumber: "01",
    description:"Organizational capacity/capability assessment (OCA) is about assessing the organization against a designated reference, benchmark, or a set of requirements / needs. <strong>The assessment is meant to identify and analyze the gaps that are needed to be covered, priorities, and constraints on resources and results. </strong>",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "CapabilityDevelopment/CapabilityDevelopment2.svg",
    subServiceIconAlt: "Organizational Capacity/Capability Development (OCD)",
    subServiceName: "Organizational Capacity/Capability Development (OCD)",
    subServiceNumber: "02",
    description:"Organizational capacity/capability development (OCD) is about developing the organization capacity/capability in response to a pre-applied (OCA) or based on a tailored need / request. The development is meant to provide solutions to <strong> bridge the gaps that are needed to be covered so the organization achieves the desired results.",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "CapabilityDevelopment/CapabilityDevelopment3.svg",
    subServiceIconAlt:
      "Organizational Knowledge Management Capability Development",
    subServiceName:
      "Organizational Knowledge Management Capability Development",
    subServiceNumber: "03",
    description:"Organizational knowledge management capability development is about helping organizations establish and maintain the mechanism to continually and intentionally <strong>create and use knowledge as a strategic capability to drive performance, achieve results, and improve stakeholders satisfaction. </strong>",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "CapabilityDevelopment/CapabilityDevelopment4.svg",
    subServiceIconAlt: "Organizational Transformation Capability Development",
    subServiceName: "Organizational Transformation Capability Development",
    subServiceNumber: "04",
    description:"Organizational sustainability capability development is about helping organizations to identify and improve the transformation capability of values, focus, objectives, and strategic focus and capabilities to <strong>identify key initiatives to re-orient the current organization and transform processes to deliver value to customers and businesses. </strong>",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "CapabilityDevelopment/CapabilityDevelopment5.svg",
    subServiceIconAlt: "Organizational Sustainability Capability Development",
    subServiceName: "Organizational Sustainability Capability Development",
    subServiceNumber: "05",
    description:"Organizational sustainability capability development is about helping organizations to identify and improve the capability of organizational sustainability and <strong>a long-term value, using the triple bottom line which considers environmental and social factors in addition to financial performance.</strong>",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "CapabilityDevelopment/CapabilityDevelopment6.svg",
    subServiceIconAlt:
      "Organizational Policy Management Capability Development",
    subServiceName: "Organizational Policy Management Capability Development",
    subServiceNumber: "06",
    description:"Organizational policy management capability development is about helping organizations to identify and improve the capability of policy management, including policies, procedures, and data/records management, to best <strong>tackle governance, performance, risks, and compliance objectives in a systemized and predictable manner. </strong>",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "CapabilityDevelopment/CapabilityDevelopment7.svg",
    subServiceIconAlt: "Capability Development Framework",
    subServiceName: "Capability Development Framework",
    subServiceNumber: "07",
    description:"Capability development framework (CDF) is how organization <strong>outlines what skills, knowledge and behaviours are expected of their employees to fulfil their roles to meet business priorities</strong>. A successful CDF drives recruitment, development opportunities and performance in workforce",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "CapabilityDevelopment/CapabilityDevelopment8.svg",
    subServiceIconAlt: "Capability and Maturity Improvement",
    subServiceName: "Capability and Maturity Improvement",
    subServiceNumber: "08",
    description:"Capability and maturity improvement is about helping organization to acquire specific set of capabilities, or enhanced maturity in some capability, to <strong> match a defined requirement to complete a task or project defined by a stakeholder. <strong>",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "CapabilityDevelopment/CapabilityDevelopment9.svg",
    subServiceIconAlt:
      "Organizations Preparation for Assessment/Audit/Verification against Quality and Excellence Standards",
    subServiceName: "Organizations Preparation for Assessment/Audit/Verification against Quality and Excellence Standards ",
    subServiceNumber: "09",
    description:"Organizations prior to being audited, assessed, or verified against specified standards or requirements, need to <strong>be well prepared to properly pass right from the right first time</strong>, preparation encompasses a wide spectrum of activities, including but not limited to OCA, OCD, Training, Deployment, internal audit / assessment, external audit / assessment rehearsal, and finally following up after conducting the external activity.",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "CapabilityDevelopment/CapabilityDevelopment10.svg",
    subServiceIconAlt:
      "Preparing Capacity/Capability Assessment and Development Teams | Office",
    subServiceName:
      "Preparing Capacity/Capability Assessment and Development Teams | Office",
    subServiceNumber: "10",
    description:"Preparing capacity/capability assessment and development teams is about building the team’s shared vision, approach, rules, and mechanisms to conduct policy management initiatives properly, and <strong> making sure that the designated team will champion change in the organization, and successfully manage capacity/capability assessment and development internal and external stakeholders. </strong>",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "CapabilityDevelopment/CapabilityDevelopment11.svg",
    subServiceIconAlt: "Preparing Strategy Teams | Office",
    subServiceName: "Preparing Strategy Teams | Office",
    subServiceNumber: "11",
    description:"Preparing strategy management teams is about building the team’s shared vision, approach, rules, and mechanisms to conduct policy management initiatives properly, <strong> making sure that the designated team will champion change in the organization, and successfully manage strategy management internal and external stakeholders. </strong>",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "CapabilityDevelopment/CapabilityDevelopment12.svg",
    subServiceIconAlt: "Preparing Policy Management Teams | Office",
    subServiceName: "Preparing Policy Management Teams | Office",
    subServiceNumber: "12",
    description:"Preparing policy management teams is about building the team’s shared vision, approach, rules, and mechanisms to conduct policy management initiatives properly, <strong> making sure that the designated team will champion change in the organization, and successfully manage policy management internal and external stakeholders. </strong>",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "CapabilityDevelopment/CapabilityDevelopment13.svg",
    subServiceIconAlt: "Preparing Quality Teams | Office",
    subServiceName: "Preparing Quality Teams | Office",
    subServiceNumber: "13",
    description:"Preparing quality teams is about building the team’s shared vision, approach, rules, and mechanisms to conduct quality and excellence initiatives properly,<strong> making sure that the designated team will champion change in the organization, and successfully manage quality and excellence internal and external stakeholders. </strong>",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
];
