import React from "react";
import styles from "./BannerExpertiseWhyQuaim.module.css";

const IndividualImage = (props) => {
  const showText = () => {
    if (props.id) {
      const text = document.getElementById(props.id);
      text.style.opacity = 1; //
    }
  };
  const hideText = () => {
    if (props.id) {
      const text = document.getElementById(props.id);
      text.style.opacity = 0; //
    }
  };
  return (
    <div className={`${styles.imageItem} ${props.classImg} `}>
      <span id={props.id} className={`${styles.text} ${props.classtext}`}>
        {props.textDes}
      </span>
      <img
        onMouseEnter={showText}
        onMouseLeave={hideText}
        className = {`us-icon`}
        src={`/assets/img/UsPage/${props.nameImg}`}
        alt="why Quaim"
      />
    </div>
  );
};
export default IndividualImage;
