import { useTranslation } from "react-i18next";
import Getdata from "../../../Apis/Getdata";
const ClientsData = (id) => {
    const [tKey, i18n] = useTranslation();
    const data = (!id)?Getdata("/clients"):Getdata("/client/"+id);
    let ClientsArray = [];
    if(data.length>0 && !id) { 
        data.map((element, index) => {
            ClientsArray[index] ={
                'cell_index':element.client_id,
                'cell_img':"../assets/img/YouPage/clients/"+(index+1)+".jpg",
               // 'cell_img':"../assets/img/YouPage/clients/1.jpg",
                'cell_title':(i18n.language === "en") ? element.client_name_en :element.client_name_ar,
                'cell_link':"#",
                'cell_description':((i18n.language === "en") ? element.sector_name_en:element.sector_name_ar)+' Sector',
            }
        });
    }else ClientsArray = data;
    return ClientsArray;
}
export default ClientsData;