import React from "react";
import { Card, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./CardT.module.css";
import HighlightedText from "./HighlightedText";
import RatingStar from "./RatingStar";
const CardT = (props) => {
  const [tKey] = useTranslation();
  return (
    <Col xl={3} className={`col-auto m-4 `}>
      <Card className={`${styles.card}`}>
        <Card.Header className={`${styles.cardHeader}`}>
          <img
            className={`${styles.cardImg}`}
            src={`/assets/img/YouPage/Testimonials/${props.nameImg}`}
            alt={"IMG"}
          />
        </Card.Header>
        <Card.Body className={`${styles.cardBody}`}>
          <Card.Title className={`${styles.textTitle}`}>
          <RatingStar key={1} />
          <RatingStar key={2} />
          <RatingStar key={3} />
          <RatingStar key={4} />
          <RatingStar key={5} />
          <br></br>
            {tKey(props.textTitle)}
            <h5 className={`${styles.titleContaint}`}>
              {tKey(props.textTitleContent)}
            </h5>
          </Card.Title>
          <Card.Text className={`${styles.cardText}`}>
            <HighlightedText
              highlightedTextClasses={props.highlightedTextClasses}
              textArray={props.textArray}
            />
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};
export default CardT;
