import React from "react";
import { Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TitleRowWithIcons from "./TitleRowWithIcons";
import "./OurServices.css";

const OurServices = () => {
  const [tKey] = useTranslation();
  return (
    <section className="our-services">
      <Container>
        <Row className="text-center mt-5">
          <h2 className="text-white mb-4 text-uppercase">
            {tKey("our Services")}
          </h2>
          <hr className="h-line" />
        </Row>
        <Row>
          <TitleRowWithIcons />
        </Row>
      </Container>
    </section>
  );
};

export default OurServices;
