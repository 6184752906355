import "./hexagon.css";
function Gridcell(props) {
  console.log(props);
  return (
    <li  key={props.cell_attr.cell_link}    className="hex"  >
        <div className="hexIn">
          <a className="hexLink" href={props.cell_attr.cell_link}>
            <img src={props.cell_attr.cell_img} alt="" /><h2> {props.cell_attr.cell_title}</h2>
            <h1> {props.cell_attr.cell_title}</h1>
            <p>{props.cell_attr.cell_description}</p>
          </a>
        </div>
      </li>
  );
}
export default Gridcell;
