export const validationLan = () => {
  let elements = document.getElementsByTagName("*");
  let isArabic = localStorage.getItem("lan") === "AR";
  if (isArabic) {
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.letterSpacing = "normal";
    }
  } else {
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.letterSpacing = "";
    }
  }
  let url = window.location.href;
  let url_string = url.toString();
  if (url_string.includes("contact-us") !== false || url_string.includes("expert")  || url_string.includes("partner") || url_string.includes("intern") ) {
    let contactUsBTN = document.getElementById("contactBTN");
    contactUsBTN.style.display = "none";
  } else {
    let contactUsBTN = document.getElementById("contactBTN");
    if(contactUsBTN !== undefined) contactUsBTN.style.display = "";
  }
};
