import React, { useContext, useEffect } from "react";
import BannerCustomerCentricity from "./Body/BannerCustomerCentricity";
import { validationLan } from "../../../ValidationLan/ValidationLan";
import currentLanguageContext from "../../Context/Context";

const CustomerCentricity = () => {
  const lang = useContext(currentLanguageContext);

  useEffect(() => {
    validationLan();
    changeImage();
  }, [lang.lan]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const changeImage = () => {
    let Component1_2IMG = document.getElementById("Component1–2IMG");
    if (lang.lan) {
      Component1_2IMG.src = "/assets/img/PortfolioPage/Component 1 – 2-Ar.png";
    } else {
      Component1_2IMG.src = "/assets/img/PortfolioPage/Component 1 – 2.png";
    }
  };
  return <BannerCustomerCentricity />;
};

export default CustomerCentricity;
