import "./App.css";
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./Components/HomePage/HomePage";
import Story from "./Components/USPage/Story/Story";
import NavBar from "./Components/Header/NavBar";
import Footer from "./Components/Footer/Footer";
import ExpertiseWhyQUAIM from "./Components/USPage/Expertise – Why QUAIM/ExpertiseWhyQUAIM";
import ExpertisePromiseYou from "./Components/USPage/Expertise – What We Promise You/ExpertisePromiseYou";
import PhilosophyValueTriangle from "./Components/USPage/Philosophy -Value-Triangle/PhilosophyValueTriangle";
import PhilosophyHexastagesProcess from "./Components/USPage/Philosophy - Hexastages Process/PhilosophyHexastagesProcess";
import CustomerCentricity from "./Components/PortfolioPage/Customer Centricity/CustomerCentricity";
import StrategySystems from "./Components/PortfolioPage/ValueProposition/StrategySystems/StrategySystems";
import CapabilityDevelopment from "./Components/PortfolioPage/ValueProposition/Capability Development/CapabilityDevelopment";
import OrganizationalTransformation from "./Components/PortfolioPage/ValueProposition/Organizational Transformation/OrganizationalTransformation";
import Shifts from "./Components/PortfolioPage/Shifts/Shifts";
import Projects from "./Components/YouPage/Projects/Projects";
import Project from "./Components/YouPage/Projects/Project";
import Clients from "./Components/YouPage/Clients/Clients";
import Client from "./Components/YouPage/Clients/Client";
import Testimonials from "./Components/YouPage/Testimonials/Testimonials";
import ContactUsPage from "./Components/Contact Us/ContactUsPage";
import currentLanguageContext from "./Components/Context/Context";
import Blog from "./Components/BlogPage/Blog";
import BlogPage from "./Components/BlogPage/BlogPageContent/BlogPage";
import Expert from "./Components/TogetherPage/Expert/Expert";
import Partner from "./Components/TogetherPage/Partner/Partner";
import Intern from "./Components/TogetherPage/Intern/Intern";
import ServicesCapabilityDevelopment from "./Components/Services/ServicesCapabilityDevelopment/ServicesCapabilityDevelopment";
import ServicesStrategyAndSystems from "./Components/Services/ServicesStrategyAndSystems/ServicesStrategyAndSystems";
import ServicesOrganizationalTransformation from "./Components/Services/ServicesOrganizationalTransformation/ServicesOrganizationalTransformation";
function App() {
  const [lan, setLan] = useState(false);
  const isLan = { lan, setLan };
  //   const [isContactus, setIsContactus] = useState(false);
  //   const isContactusValue = { isContactus, setIsContactus };

  let activePage;
  useEffect(() => {
    switch (window.location.pathname) {
      case "/":
        activePage = <Route path="/" element={<HomePage />} />;
        break;
      case "home":
        activePage = <Route path="/home" element={<HomePage />} />;
        break;
      case "/us/story":
        activePage = <Route path="/us/story" element={<Story />} />;
        break;
      case "/us/expertise/why-quaim":
        activePage = (
          <Route
            path="/us/expertise/why-quaim"
            element={<ExpertiseWhyQUAIM />}
          />
        );
        break;
      case "/us/expertise/Promise-You":
        activePage = (
          <Route
            path="/us/expertise/Promise-You"
            element={<ExpertisePromiseYou />}
          />
        );
        break;
      case "/us/philosophy/value-triangle":
        activePage = (
          <Route
            path="/us/philosophy/value-triangle"
            element={<PhilosophyValueTriangle />}
          />
        );
        break;
      case "/us/philosophy/HexastagesProcess":
        activePage = (
          <Route
            path="/us/philosophy/HexastagesProcess"
            element={<PhilosophyHexastagesProcess />}
          />
        );
        break;
      /*--*/
      case "/portfolio/customer-centricity":
        activePage = (
          <Route
            path="/portfolio/customer-centricity"
            element={<CustomerCentricity />}
          />
        );
        break;
      case "/portfolio/value-Proposition/strategy-systems":
        activePage = (
          <Route
            path="/portfolio/value-Proposition/strategy-systems"
            element={<StrategySystems />}
          />
        );
        break;
      case "/portfolio/value-Proposition/capability-development":
        activePage = (
          <Route
            path="/portfolio/value-Proposition/capability-development"
            element={<CapabilityDevelopment />}
          />
        );
        break;
      case "/portfolio/value-Proposition/organizational-transformation":
        activePage = (
          <Route
            path="/portfolio/value-Proposition/organizational-transformation"
            element={<OrganizationalTransformation />}
          />
        );
        break;
      case "/portfolio/value-Proposition/shifts":
        activePage = (
          <Route
            path="/portfolio/value-Proposition/shifts"
            element={<Shifts />}
          />
        );
        break;
      /*--*/
      case "/you/projects":
        activePage = <Route path="/you/projects" element={<Projects />} />;
        break;
      case "/you/project":
        activePage = <Route path="/you/project/:id" element={<Project />} />;
        break;
      case "/you/clients":
        activePage = <Route path="/you/clients" element={<Clients />} />;
        break;
      case "/you/client/:id":
        activePage = <Route path="/you/client/:id" element={<Client />} />;
        break;
      case "/you/testimonials":
        activePage = (
          <Route path="/you/testimonials" element={<Testimonials />} />
        );
        break;
      case "/contact-us":
        activePage = <Route path="/contact-us" element={<ContactUsPage />} />;
        break;
      case "/blog":
        activePage = <Route path="/blog" element={<Blog />} />;
        break;
      /*--*/
      case "/blog-page":
        activePage = <Route path="/blog-page" element={<BlogPage />} />;
        break;
      case "/services/strategy-and-systems":
        activePage = (
          <Route
            path="/services/strategy-and-systems"
            element={<ServicesStrategyAndSystems />}
          />
        );
        break;
      case "/services/capability-development":
        activePage = (
          <Route
            path="/services/capability-development"
            element={<ServicesCapabilityDevelopment />}
          />
        );
        break;
      case "/services/organizational-transformation":
        activePage = (
          <Route
            path="/services/organizational-transformation"
            element={<ServicesOrganizationalTransformation />}
          />
        );
        break;
      case "/together/expert":
        activePage = <Route path="/together/expert" element={<Expert />} />;
        break;
      case "/together/intern":
        activePage = <Route path="/together/intern" element={<Intern />} />;
        break;
      case "/together/partner":
        activePage = <Route path="/together/partner" element={<Partner />} />;
        break;
      default:
        activePage = <Route path="/" element={<HomePage />} />;
        break;
    }
  }, [window.location.pathname]);

  return (
    <currentLanguageContext.Provider value={isLan}>
      <NavBar />
      <Routes>
        {/*  {activePage}*/}
        <Route path="/home" element={<HomePage />} />
        <Route path="/" element={<HomePage />} />
        {/*  Us Page*/}
        <Route path="/us/story" element={<Story />} />
        <Route path="/us/expertise/why-quaim" element={<ExpertiseWhyQUAIM />} />
        <Route
          path="/us/expertise/Promise-You"
          element={<ExpertisePromiseYou />}
        />
        <Route
          path="/us/philosophy/value-triangle"
          element={<PhilosophyValueTriangle />}
        />
        <Route
          path="/us/philosophy/HexastagesProcess"
          element={<PhilosophyHexastagesProcess />}
        />
        {/*    Portfolio Page*/}
        <Route
          path="/portfolio/customer-centricity"
          element={<CustomerCentricity />}
        />
        <Route
          path="/portfolio/value-Proposition/strategy-systems"
          element={<StrategySystems />}
        />
        <Route
          path="/portfolio/value-Proposition/capability-development"
          element={<CapabilityDevelopment />}
        />
        <Route
          path="/portfolio/value-Proposition/organizational-transformation"
          element={<OrganizationalTransformation />}
        />
        <Route
          path="/portfolio/value-Proposition/shifts"
          element={<Shifts />}
        />
        {/*   You Page*/}
        <Route path="/you/projects" element={<Projects />} />
        <Route path="/you/project/:id" element={<Project />} />
        <Route path="/you/clients" element={<Clients />} />
        <Route path="/you/client/:id" element={<Client />} />
        <Route path="/you/testimonials" element={<Testimonials />} />
        {/* Contact Us Page */}
        <Route path="/contact-us" element={<ContactUsPage />} />
        {/*     Blog Page*/}
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-page" element={<BlogPage />} />
        {/*  Services*/}
        <Route
          path="/services/strategy-and-systems"
          element={<ServicesStrategyAndSystems />}
        />
        <Route
          path="/services/capability-development"
          element={<ServicesCapabilityDevelopment />}
        />
        <Route
          path="/services/organizational-transformation"
          element={<ServicesOrganizationalTransformation />}
        />
        {/* Together Page*/}
        <Route path="/together/expert" element={<Expert />} />
        <Route path="/together/intern" element={<Intern />} />
        <Route path="/together/partner" element={<Partner />} />
      </Routes>
      <Footer />
    </currentLanguageContext.Provider>
  );
}

export default App;
