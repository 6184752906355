import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./Contactus.module.css";
const Contactus = (props) => {
  const [tKey, i18n] = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container id={`${props.text !== "Send" ? "contactBTN" : ""}`}>
      <Row
        xl={6}
        className={`row justify-content-center align-items-center ${styles.Row} `}
      >
        <Col xl={12} className="col-auto text-center">
          <Link
            className="text-decoration-none m-auto link-light"
            to={"/contact-us"}
          >
            <button className={`${styles.button}`}>{tKey(props.text)}</button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};
export default Contactus;
