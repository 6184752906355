import React, { useState } from "react";
import { Col } from "react-bootstrap";
import styles from "./CardServices.module.css";

const CardServices = (props) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <Col
      xl={3}
      className={`${styles.card} ${isFlipped ? `${styles.flipped}` : ""}`}
      onMouseEnter={handleFlip}
      onMouseLeave={handleFlip}
    >
      <div className={`${styles.side}  ${styles.front}`}>
        <h3> {props.num}</h3>
        <h2>{props.title}</h2>
      </div>
      <div className={` row-cols ${styles.side}  ${styles.back}`}>
        <div className="info">
          <div className="mt-4 ">
            <p>{props.description}</p>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default CardServices;
