import React from "react";
import Header from "../../Header/Header";
import CardLeftV from "./CardLeftV";
import CardRightV from "./CardRightV";
import styles from "../../Expertise – What We Promise You/Body/BannerExpertisePromiseYou.module.css";
import styleText from "./../../../../Components/PortfolioPage/ValueProposition/StrategySystems/Body/BannerStrategySystems.module.css";

const BannerExpertisePromiseYou = () => {
  return (
    <section className={`container-fluid ${styles.PromiseYou}`}>
      <Header
        classes={styles.textContentHeader}
        text="What We Promise You?"
        textContent=""
        nameVideo={"Header.mp4"}
      />
      /
      <CardRightV
        nameImg={"Promise You_1.png"}
        highlightedTextClasses={styleText.textColor}
        textArray={[
          "",
          "We ",
          "deliver a ",
          "unique knowledge experience, You ",
          "will grasp the difference on all levels as individuals, teams, and organization.",
        ]}
      />
      <CardLeftV
        nameImg={"Promise You_2.png"}
        highlightedTextClasses={styleText.textColor}
        textArray={[
          "",
          "We ",
          "provide ",
          "You ",
          "with the best we master, and we ",
          "expand our proposed value with our strategic partners, ",
          "all unified into a ",
          "quality framework of QUAIM ",
          "that creates difference.",
        ]}
      />
      <CardRightV
        nameImg={"Promise You_3.png"}
        highlightedTextClasses={styleText.textColor}
        textArray={[
          "",
          "You1 ",
          "will not wait till the end to preview the product, as ",
          "We ",
          "will keep You acquainted with interim product(s) and process progress evolutions, and ",
          "You will continually provide your valuable inputs and perceptions.",
        ]}
      />
      <CardLeftV
        nameImg={"Promise You_4.png"}
        highlightedTextClasses={styleText.textColor}
        textArray={[
          "",
          "We1 ",
          "and ",
          "You2 ",
          "will start and build on the ",
          "(Business Analysis Value) ",
          "that We aim to improve, and We conclude by measuring the impact and shift on the very (Business Analysis Value) We started from.",
        ]}
      />
      <CardRightV
        nameImg={"Promise You_5.png"}
        highlightedTextClasses={styleText.textColor}
        textArray={[
          "",
          "YOUR ",
          "KNOWLEDGE CAPITAL AND TRANSFORMATIONAL CAPABILITIES ",
          "WILL GROW UPON EACH EXPERIENCE ",
          "WITH ",
          "US.",
        ]}
      />
      <CardLeftV
        nameImg={"Promise You_6.png"}
        highlightedTextClasses={styleText.textColor}
        textArray={[
          "",
          "OUR ",
          "CAPABILITIES, KNOWLEDGE, AND EXPERTISE IN QUAIM ARE PUT IN PLACE TO SERVE OUR CLIENTS IN ",
          "MAKING SHIFTS IN THEIR BUSINESSES, ENVIRONMENTS, AND SOCIETIES.",
        ]}
      />
    </section>
  );
};
export default BannerExpertisePromiseYou;
