import React from "react";
import "./ServicesDetailsSection.css";
import ImgSection from "./ImgSection";

const ServicesIconOnHover = (props) => {
  return (
    <>
      <ImgSection
        service={props.service}
        folderName={"static"}
        classes={" d-block "}
      />
    </>
  );
};

export default ServicesIconOnHover;
