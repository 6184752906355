import React from "react";
import { Container } from "react-bootstrap";
import ServicesSection from "../ServicesSection/ServicesSection";

const ServicesContent = (props) => {
  return (
    <Container fluid className="mt-5 p-0">
      {props.dataArray?.map((service, index) => {
        return (
          <ServicesSection service={service} key={index} direction={index} />
        );
      })}
    </Container>
  );
};

export default ServicesContent;
