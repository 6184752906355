import React, { useEffect } from "react";
import Header from "../Components/Header/Header";
import ServicesContent from "../Components/Content/ServicesContent";
import { OrganizationalTransformationData } from "./../Data/OrganizationalTransformationData";

const ServicesOrganizationalTransformation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header
        img="ServicesHeader.png"
        icon="OrganizationalTransformationHeader.png"
        text="Organizational Transformation"
      />
      <ServicesContent dataArray={OrganizationalTransformationData} />
    </>
  );
};

export default ServicesOrganizationalTransformation;
