import React from "react";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import "./CardImage.css";

const CardImage = (props) => {
  return (
    <Col className="CardImage" size={12} sm={6} md={4}>
      <Link
        className="text-decoration-none m-auto  link-light "
        to={"/you/clients"}
      >
        <img src={props.src} alt="card" />
        <div className="mt-4"></div>
      </Link>
    </Col>
  );
};
export default CardImage;
