import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Header from "../../../Header/Header";
import RowImg from "../../../RowImg";
import styles from "./BannerStrategySystems.module.css";
import RowText from "./RowText";
import stylesC from "../../Capability Development/Body/./BannerCapabilityDevelopment.module.css";
import stylesP from "./../../../../PortfolioPage/Customer Centricity/Body/BannerCustomerCentricity.module.css";
import styleText from "./../../../../../Components/PortfolioPage/ValueProposition/StrategySystems/Body/BannerStrategySystems.module.css";
import HighlightedText from "../../../Customer Centricity/Body/HighlightedText";

const BannerStrategySystems = () => {
  const [tKey] = useTranslation();
  return (
    <section>
      <Container className={""} dir="">
        <Header
          text="PORTFOLIO"
          classesText={styles.textHeader}
          classes={styles.textHeaderChild}
          textContent="Strategy & Systems"
          nameVideo={"Header.m4v"}
        />
        /
        <RowImg
          id="StrategySystems_1Img"
          classImg={stylesP.Img}
          // classImg={styles.Img}
          nameImg={"StrategySystems_1.png"}
        />
        <RowText
          highlightedTextClasses={styleText.textColor}
          textArray={[
            "Together, we develop business direction and outcomes, using analytical and innovative methodologies to develop",
            " Business Models, Plans, and Strategies, ",
            "and to identify objectives, indicators, and initiatives, and developing",
            " Business Architecture ",
            "of capabilities, data, processes, products/services, functions, and stakeholders.",
          ]}
        />
        <RowImg
          id="Component1_1Img"
          classImg={stylesP.Img}
          // classImg={styles.imgBody}
          nameImg={"Component 1 – 1.png"}
        />
        <RowText
          highlightedTextClasses={styleText.textColor}
          textArray={[
            "We design organizational systems that realize business models and strategies, through",
            " Governance, Performance Management, Risk Management, Compliance Management, and Quality /Excellence Frameworks, ",
            "which interconnects systems, components, and elements together into a continual and sustainable improvement approach, that fulfills strategy and satisfies stakeholders inspirations.",
          ]}
        />
        <Row xl={6} className={` row justify-content-center  ${styles.rowVid}`}>
          <Col xl={4} className={`col-auto ${styles.textVideo}`}>
            <HighlightedText
              highlightedTextClasses={styleText.textColor}
              textArray={[
                "We foster strategies and systems through",
                " Organizational Culture ",
                "identification and development, as a core enabler to direct visions, behaviors, resources allocation and utilization, as well as results understanding and evaluation.",
              ]}
            />
          </Col>
          <Col xl={6} className={`col-auto `}>
            <video
              className={`${styles.vidC}`}
              autoPlay={true}
              loop={true}
              muted
            >
              <source src="/assets/video/PortfolioPage/Tree.mp4" />
            </video>
          </Col>
        </Row>
        <Row xl={6} className={` row justify-content-center `}>
          <Col xl={10} className={`col-auto ${stylesC.TextFooter}`}>
            <p className={` ${stylesC.textF} ${styles.textFS}`}>
              {tKey(
                "Our strategy and systems expertise in QUAIM Global are offered to you, so you empower your organizations in a resilient and responsive manner with internal and external variables, and make the shifts for your clients, teams, and societies."
              )}
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BannerStrategySystems;
