export const OrganizationalTransformationData = [
  {
    subServiceIcon:
      "OrganizationalTransformation/OrganizationalTransformation1.svg",
    subServiceIconAlt: "Transformation Requirements Assessment",
    subServiceName: "Transformation Requirements Assessment",
    subServiceNumber: "01",
    description:"Transformation requirements assessment is about understanding the <strong>readiness of the organization to accept change, identifying the issues, and then dealing with them in the Implementation and migration plans</strong> as a key to successful transformation. This will be a joint effort between corporate (especially human resources) staff, lines of business, and IT planners.",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon:
      "OrganizationalTransformation/OrganizationalTransformation2.svg",
    subServiceIconAlt: "Transformation Roadmap Development",
    subServiceName: "Transformation Roadmap Development",
    subServiceNumber: "02",
    description:"Transformation roadmap development is about defining the necessary arrangements, milestones, and strategy to perform transformation, whether the business is shifting to a new operating model, innovating the customer experience, helping the team to become tech-powered, enhancing operations, or reinventing the whole business, the roadmap would help drive outcomes through engagement and beyond.",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon:
      "OrganizationalTransformation/OrganizationalTransformation3.svg",
    subServiceIconAlt: "Preparing Transformation Teams",
    subServiceName: "Preparing Transformation Teams",
    subServiceNumber: "03",
    description:"Preparing transformation teams is about building the team’s shared vision, approach, rules, and mechanisms to conduct transformation properly, champion change in the organization, and successfully manage transformation internal and external stakeholders. ",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon:
      "OrganizationalTransformation/OrganizationalTransformation4.svg",
    subServiceIconAlt: "Digital Transformation ",
    subServiceName: "Digital Transformation ",
    subServiceNumber: "04",
    description:"Digital transformation is about helping businesses transform and evolve quickly <strong> to seize the opportunities and help mitigate the risks that digital transformation creates, especially assessing the alternatives and costs/benefits of digital transformation. </strong>",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  }
];
