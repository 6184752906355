import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ServicesContactSection.css";

const ServicesContactSection = (props) => {
  return (
    <Col className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 mb-5">
      <Link className="text-decoration-none" to={props.item.link}>
        <Row className="contact-section">
          <Col className="col-8 align-self-center">
            <h5>{props.item.header}</h5>
            <p>{props.item.text}</p>
            <p>{props.item.details}</p>
          </Col>
          <Col className="col-4 text-center">
            <img
              className="contact-img"
              src={`/assets/img/Services/${props.item.icon}`}
              alt={props.item.alt}
            />
          </Col>
        </Row>
      </Link>
    </Col>
  );
};

export default ServicesContactSection;
