import React from "react";
import { Row, Col } from "react-bootstrap";
import ServicesDetailsNumbrSection from "./ServicesDetailsNumbrSection";
import ServicesDropDownSection from "./ServicesDropDownSection";
import ServicesIconOnHover from "./ServicesIconOnHover";
import "./ServicesDetailsSection.css";

const RightDirectionService = (props) => {
  return (
    <div className="service-section px-7">
      <Row className="pt-2">
        <Col className="col-12 pt-2">
          <ServicesIconOnHover service={props.service} />
        </Col>
      </Row>
      <Row>
        <ServicesDetailsNumbrSection service={props.service} />
      </Row>
    </div>
  );
};

export default RightDirectionService;
