import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./IconLink.css";

const LinkText = (props) => {
  const [tKey] = useTranslation();
  return (
    <Link to={props.link} className="px-2 text-white d-block mb-3">
      {tKey(props.text)}
    </Link>
  );
};
export default LinkText;
