import React, { useEffect } from "react";
import Header from "../Components/Header/Header";
import ServicesContent from "../Components/Content/ServicesContent";
import { StrategyAndSystemsData } from "./../Data/StrategyAndSystemsData";

const ServicesStrategyAndSystems = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header
        img="ServicesHeader.png"
        icon="StrategyAndSystemsHeader.png"
        text="Strategy And Systems"
      />
      <ServicesContent dataArray={StrategyAndSystemsData} />
    </>
  );
};

export default ServicesStrategyAndSystems;
