import React, { useEffect } from "react";
import BannerProject from "./Body/BannerProject";
import { validationLan } from "../../../ValidationLan/ValidationLan";

const Project = () => {
  let isArabic = localStorage.getItem("lan") === "AR";
  useEffect(() => {
    validationLan();
  }, [isArabic]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <BannerProject />;
};

export default Project;
