import React from "react";
import { Col, Row } from "react-bootstrap";
import "./ServicesDetailsSection.css";
import ReactHtmlParser from 'html-react-parser';
const ServicesDetailsNumbrSection = (props) => {
  return (
    <Col className="col-lg-12 col-12 text-white text-uppercase">
      <div className="services-details-numbers">
        <Row className="services-numbers">
          <Col className="col-4">
            <span className="service-dot my-4"></span>
          </Col>
          <Col className="col-8">
            <span className="service-number">
              &nbsp;{props.service.subServiceNumber}
            </span>
          </Col>
        </Row>
        <div className="services-details">
          <h2 className="my-4 service-name">{props.service.subServiceName}</h2>
          <p className="service-description">{ReactHtmlParser(props.service.description)}</p>
        </div>
      </div>
    </Col>
  );
};

export default ServicesDetailsNumbrSection;
