import React from "react";
import "./ServicesDetailsSection.css";

const ImgSection = (props) => {
  return (
    <img
      src={`/assets/img/Services/${props.folderName}/${props.service.subServiceIcon}`}
      alt={props.service.subServiceIconAlt}
      className={` service-icon ${props.classes}`}
    />
  );
};

export default ImgSection;
