import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SiteScroll from "../../SiteScroll/SiteScroll";
import "./BannerHome.css";

const BannerHome = () => {
  const [tKey] = useTranslation();
  return (
    <section className="banner" id="home">
      <Container className="" dir="">
        <video className="bgVid" autoPlay={true} loop={true} muted>
          <source src="/assets/video/HomePage/Header.mp4" />
        </video>
        <Row xl={6} className="justify-content-center home">
          <Col xl={5} className="col-auto">
            <div className="content-home">
              <p className="pt-5">{tKey("WE TRANSFORM YOUR")}</p>
              <p style={{ color: "#9D20B1", top: "620px" }}>
                {tKey("PRESENT AND FUTURE")}
              </p>
              <svg
                className="line"
                xmlns="http://www.w3.org/2000/svg"
                width="300"
                height="20"
                viewBox="0 0 219 3"
              >
                <line
                  id="Line_1"
                  data-name="Line 1"
                  x2="216"
                  transform="translate(1.5 1.5)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="3"
                />
              </svg>
              <p className="text-four">
                {tKey("TO A SEAMLESS CUSTOMER EXPERIENCE THAT EASILY CONVERTS")}
              </p>
              <SiteScroll />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default BannerHome;
