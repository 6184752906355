const basePath = "/assets/img/HomePage/Clients/";
export const clients = [
  {
    id: "1",
    src: basePath + "building-market.svg",
  },
  {
    id: "2",
    src: basePath + "direct-aid.svg",
  },
  {
    id: "3",
    src: basePath + "twakl-karman.svg",
  }
  ,
  {
    id: "4",
    src: basePath + "namaa-admin.png",
  }
];
