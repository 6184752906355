import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Header from "../../../Header/Header";
import RowImg from "../../../RowImg";
import RowText from "../../StrategySystems/Body/RowText";
import styles from "./BannerOrganizationalTransformation.module.css";
import stylesC from "../../Capability Development/Body/BannerCapabilityDevelopment.module.css";
import stylesSTR from "../../StrategySystems/Body/BannerStrategySystems.module.css";
import styleText from "./../../../../../Components/PortfolioPage/ValueProposition/StrategySystems/Body/BannerStrategySystems.module.css";

const BannerOrganizationalTransformation = () => {
  const [tKey] = useTranslation();
  return (
    <section>
      <Container className={""} dir="">
        <Header
          text="PORTFOLIO"
          classesText={stylesSTR.textHeader}
          classes={`${stylesSTR.textHeaderChild} ${styles.textHeaderChild}`}
          textContent="Organizational Transformation"
          nameVideo={"Header.m4v"}
        />
        <Row xl={6} className={` row justify-content-center `}>
          <Col xl={6} className={`col-auto `}>
            <video
              id="HeagonVID"
              className={`${styles.vidC}`}
              autoPlay={true}
              loop={true}
              muted
            >
              <source src="/assets/video/PortfolioPage/Lines.mp4" />
            </video>
          </Col>
        </Row>
        <RowText
          highlightedTextClasses={styleText.textColor}
          textArray={[
            "Organizational transformation management has become an ",
            "inevitable priority and challenge, ",
            "both on present and future perspectives, considering digitalization, technological, and business (on the levels of domain, locations, and modality) transformations.",
          ]}
        />
        <RowText
          textArray={["Our insight in QUAIM that perceiving transformation as"]}
        />
        <RowImg
          id="ComponentORT_1IMG"
          classImg={styles.ImgTow}
          nameImg={"ComponentORT_1.png"}
        />
        <RowText
          highlightedTextClasses={styleText.textColor}
          textArray={[
            "together form keys to success for an ",
            "effective and sustainable ",
            "organizational transformation.",
          ]}
        />
        <RowImg
          id="OrganizationalTransformation_2IMG"
          classImg={styles.ImgThree}
          nameImg={"Organizational Transformation_2.png"}
        />
        <RowText
          highlightedTextClasses={styleText.textColor}
          textArray={[
            "We in QUAIM provide you an ",
            "assessment ",
            "of transformation context and requirements, and a ",
            "roadmap ",
            "for transformation alternatives, stages, components, tools, and criteria. In addition to ",
            "surveillance ",
            "of transformation evolving impacts, requirements, and risks, under deep ",
            "understanding of interference and overlap between business strategic and operational cycles.",
          ]}
        />
        <Row xl={6} className={` row justify-content-center `}>
          <Col xl={10} className={`col-auto ${stylesC.TextFooter}`}>
            <p className={` ${stylesC.textF} ${styles.textFS}`}>
              {tKey(
                "QUAIM is keen that your organizational stay competent and ahead of the game for requirements of present and future, and make the shifts for your clients, teams, and societies."
              )}
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BannerOrganizationalTransformation;
