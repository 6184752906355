import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import HighlightedText from "../../../PortfolioPage/Customer Centricity/Body/HighlightedText";

const RowText = (props) => {
  const [tKey] = useTranslation();
  return (
    <Row
      xl={6}
      className={`row justify-content-center ${props.classes} ${props.bgColor}`}
    >
      <Col xl={props.xl} className={`col-auto mb-3 ${props.classesCol}`}>
        <HighlightedText
          highlightedTextClasses={props.highlightedTextClasses}
          textArray={props.textArray}
        />
      </Col>
      {props.isImage ? (
        <Col xl={10} className={`mt-4 mb-4 col-auto text-center `}>
          <img
            id={props.id}
            className={`${props.classesImg} services-icons `}
            src={`/assets/img/UsPage/${props.nameImg}`}
            alt={"#Img"}
          />
        </Col>
      ) : null}
      {props.footerTextClasses ? (
        <Col xl={props.xl} className={`col-auto ${props.classesCol}`}>
          <p className={`${props.footerTextClasses} `}>
            {props.footerTextArray?.map((text, index) => {
              return (
                <span
                  className={
                    index % 2 !== 0 ? props.highlightedTextClasses : ""
                  }
                >
                  {tKey(text)}
                </span>
              );
            })}
          </p>
        </Col>
      ) : null}
    </Row>
  );
};

export default RowText;
