import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Getdata from "../../Apis/Getdata";
const NationalitiesData = (id) => {
    const [i18n] = useTranslation();
    const data = (!id)?Getdata("/nationalities"):Getdata("/nationalities/"+id);
    let nationalitiesArray = [];
    if(data.length>0 && !id) { 
        data.map((element, index) => {
            nationalitiesArray[index] ={
                'value':element.nationality_id,
                'label':(i18n.language === "en") ? element.nationality :element.nationality
            }
        });
    }else nationalitiesArray = data;
    return nationalitiesArray;
}
export default NationalitiesData;