import React ,{ useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import {GOOGLE_CAPTCHA_KEY} from "../../../../config";
import { Col, Container, Row } from "react-bootstrap";
import styles from "../../../YouPage/You.module.css";
import stylesTO from "../../Together.module.css";
import stylesButton from "../../../Button/Button.module.css";
import { useTranslation } from "react-i18next";
import usePostRequest from '../../../Apis/usePostRequest';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DomainsData from "../../../Settings/Data/DomainsData";
import NationalitiesData from "../../../Settings/Data/NationalitiesData";
function FormPartner() {
  const { register, handleSubmit, reset,formState: { errors } } = useForm();
  const onSubmit = (data) => {
   
    if (isCaptchaVerified) {
       handleSubmit1();
    } else {
      alert('Please verify the reCAPTCHA before submitting the form.');
    
    }
    
  }
  const [isCaptchaVerified, setCaptchaVerified] = useState(false);
  const domains = DomainsData(0);
  const nationalities = NationalitiesData(0);
  const [tKey] = useTranslation();
  const [data, setData] = useState(null);
  const [file, setFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedName, setSelectedName] = useState("");
  const post = usePostRequest();
  const [formData, setFormData] = useState({
    partner_name_en: '',
    domain_id: '',
    email:'',
    document:'',
    nationality:'',
    description:'',
    added_by:'1'
    });
    const handleCaptchaChange = (value) => {
      // Callback function triggered when reCAPTCHA is verified
      setCaptchaVerified(true);
    }; 
  // Handle changes in form inputs
    const handleChange = (event) => {
        const { name, value, files } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: files ? files : value // If it's a file input, store the file, otherwise store the value
        }));
    };
      const handleClick = () => {
        document.getElementById("real-file").click();
    };
    const handleFileChange = (event) => {
      let doc_input = '';
      console.log(event.target.files[1].name);
      for( let i=0; i< event.target.files.length ;i++ ){
        doc_input += event.target.files[i].name+' ';
      }
      setSelectedName(doc_input);
      handleChange(event);
    };
    const handleSubmit1 = async () => {
      const responseData = await post('/partner', formData);
      setData(responseData);
      reset({
        partner_name_en: '',
        domain_id: '',
        email:'',
        document:'',
        nationality:'',
        description:'',
        added_by:'1'
      });
    };    
  return (
    <Container dir="">
      <ToastContainer  />
       <form 
       //onSubmit={handleSubmit1}
       onSubmit={handleSubmit(onSubmit)} 
       encType = {`multipart/form-data`} 
       >
      <Row xl={6} className={` row justify-content-center `}>
        <Col xl={3} className={`col-md-3  row justify-content-center`}>
          <input
           {...register("partner_name_en", { required: true, maxLength: 250 })} 
            className={`${styles.input} ${stylesTO.PAinputImg_1}`}
            name={`partner_name_en`}
            type={`name`}
            placeholder={"Name"}
            value={formData.partner_name_en}
            onChange={handleChange}            
          />
          {errors.partner_name_en && <p className={`${styles.error}`} >Please check the Name </p>}
        </Col>
        <Col xl={3} className={`col-md-3 row justify-content-center`}>
          <input
           {...register("email",{
                         required: true, 
                         pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ 
                         })} 
            className={`${styles.input} ${stylesTO.PAinputImg_3}`}
            name={`email`}
            type={`email`}
            placeholder={"EMAIL"}
            value={formData.email}
            onChange={handleChange}
          />
            {errors.email && <p className={`${styles.error}`} > Please enter valid email </p>}
        </Col>
        <Col xl={3} className={`col-md-3  row justify-content-center`}>
          <select
            {...register("domain_id", { required: true})}  
            className={`${styles.input}  ${styles.inputT} ${stylesTO.PAinputImg_2}`}
            name={`domain_id`}
            id={`domain_id`}
            type={`domain`}
            placeholder={"Domain (s)"}
            value={formData.domain_id}
            onChange={handleChange} 
            >
            
            <option value="">DOMAIN</option>
                  {
                  domains.length >0?
                  domains.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  )):''}
            </select>
            {errors.domain_id && <p className={`${styles.error}`} > Please Select Domain </p>}
        </Col>
        
    </Row>
    <Row xl={6} className={` row justify-content-center `}>
      <Col xl={9} className={`${styles.colInput}  col-md-9 file-upload `} >
        <input
          className={`${styles.input} ${styles.inputT}  ${stylesTO.PAinputImg_4} `}
          name={`document_input`}
          type={`text`}
          placeholder={selectedName || "Supporting documents (CV,Certificates,testimonies)"}
          onChange={handleChange}
          onClick={handleClick}
        />
        <input 
         
          type={'file'} 
          accept="image/*,.pdf,.docx,.zip,.rar"
          name={`document`}
          id={'real-file'}
          onChange={handleFileChange}

          multiple 

          />
      </Col>
    </Row>
    <Row xl={6} className={` row justify-content-center `}>
      <Col xl={9} className={`${styles.colInput} col-md-9`}>
        <select
        {...register("nationality",{ required: true})} 
          className={`${styles.select} ${styles.inputT}  ${stylesTO.PAinputImg_5} `}
          name={`nationality`}
          type={`Nationality`}
          placeholder={"Nationality"}
          value={formData.nationality}
          onChange={handleChange}
          >
            
          <option value="">NATIONALITY</option>
          {
          nationalities.length >0?
          nationalities.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          )):''}
        </select>
        {errors.nationality && <p className={`${styles.error}`} > Please Select Nationality </p>}
      </Col>
    </Row>
    <Row xl={6} className={` row justify-content-center `}>
      <Col xl={9} className={`col-auto  col-md-9 ${styles.colInput}`}>
        <textarea
        {...register("description")}
          className={styles.textArea}
          rows={11}
          placeholder={"description"}
          name={`description`}
          type={`description`}
          value={formData.description}
          onChange={handleChange}
          
        />
      </Col>
    </Row>
    <Row
      xl={6}
      className={`row justify-content-center align-items-center  `}
    >
      <Col xl={3} className=" justify-content-center col-auto text-center mb-3 ">
      <ReCAPTCHA
        sitekey={`${GOOGLE_CAPTCHA_KEY}`}
        onChange={handleCaptchaChange}
      />
      </Col>
    </Row>
    <Row
      xl={6}
      className={`row justify-content-center align-items-center ${stylesButton.Row} `}
    >
      <Col xl={12} className="col-auto text-center">
          <button type={"submit"} className={`${stylesButton.button}`}>{tKey('SUBMIT DATA')}</button>
      </Col>
    </Row>
    </form>
    </Container>
  );
};

export default FormPartner;
