import React from "react";
import { Link } from "react-router-dom";
import "./IconLink.css";
const IconLink = (props) => {
  return (
    <Link to={props.link} target="_blank">
      <img
        className="m-2 img-size"
        src={`/assets/img/Footer/${props.imgName}`}
        alt={props.alt}
      />
    </Link>
  );
};
export default IconLink;
