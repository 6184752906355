import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../Header/Header";
import { useTranslation } from "react-i18next";
import RowImg from "../../RowImg";
import HighlightedText from "./../../../../Components/PortfolioPage/Customer Centricity/Body/HighlightedText";
import styles from "./BannerCustomerCentricity.module.css";
import stylesC from "../../ValueProposition/Capability Development/Body/BannerCapabilityDevelopment.module.css";
import styleText from "./../../../../Components/PortfolioPage/ValueProposition/StrategySystems/Body/BannerStrategySystems.module.css";

const BannerCustomerCentricity = () => {
  const [tKey] = useTranslation();
  return (
    <section>
      <Container className={""} dir="">
        <Header
          text="PORTFOLIO"
          classesText={styles.textHeader}
          classes={styles.textHeaderChild}
          textContent="Customer Centricity"
          nameVideo={"Header.m4v"}
        />
        <RowImg
          id="Component1–2IMG"
          classImg={styles.Img}
          nameImg={"Component 1 – 2.png"}
        />
        <Row xl={6} className={` row justify-content-center `}>
          <Col xl={10} className={`col-auto ${styles.text}`}>
            <HighlightedText
              highlightedTextClasses={styleText.textColor}
              textArray={[
                "Customer centricity is the ability to understand customers' situations, perceptions, and expectations. Customer centricity demands that ",
                "the customer is the focal point of all decisions ",
                "related to delivering products, services and experiences to create customer satisfaction, loyalty and advocacy.",
              ]}
            />
          </Col>
        </Row>
        <Row xl={6} className={` row justify-content-center ${styles.rowVid}`}>
          <Col xl={4} className={`col-auto ${styles.textVideo}`}>
            <HighlightedText
              highlightedTextClasses={styleText.textColor}
              textArray={[
                "WE IN QUIAM GLOBAL ADOPT ",
                "CUSTOMER CENTRICITY AS A CORNERSTONE ",
                "IN DEFINING OUR WORK PHILOSOPHY, VALUE PROPOSITION, BUSINESS MODEL, BUSINESS PROCESSES, AND OUR PRODUCTS/SERVICE DESIGN AND DELIVERY, AS WE INSIST THAT OUR WORK EMBARKS ON IDENTIFICATION OF ",
                "(BUSINESS ANALYSIS VALUE) ",
                "THAT CUSTOMERS/CLIENTS ARE KEEN TO ACHIEVE A SHIFT, AND THERE WHERE ",
                "WE MAKE THE SHIFT ",
                "THROUGH OUR ",
                "(VALUE-TRIANGLE) ",
                "AND1 ",
                "(APPROACH HEXAGON), ",
                "WHICH ARE ",
                "CENTERED AROUND OUR VALUED CUSTOMERS.",
              ]}
            />
          </Col>
          <Col xl={4} className="col-auto">
            <video
              className={`${styles.vidC}`}
              autoPlay={true}
              loop={true}
              muted
            >
              <source src="/assets/video/PortfolioPage/Comp 1.mp4" />
            </video>
          </Col>
        </Row>
        <Row xl={6} className={` row justify-content-center `}>
          <Col
            xl={10}
            className={`col-auto ${stylesC.TextFooter} ${styles.colTextFS}`}
          >
            <p className={` ${stylesC.textF} `}>
              {tKey("textBoxFooterCustomerCentricity")}
            </p>
          </Col>
        </Row>
      </Container>
      <Row className={`justify-content-center   ${styles.topImg}`}></Row>
    </section>
  );
};

export default BannerCustomerCentricity;
