import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./TitleRowWithIcons.css";

const TitleRowWithIcons = () => {
  const ourServicesData = [
    {
      icon: "StrategyAndSystem.svg",
      text: "Strategy & Systems",
      href:"./services/strategy-and-systems"
    },
    {
      icon: "CapabilityDevelopment.svg",
      text: "Capability Development",
      href:"./services/capability-development"
    },
    {
      icon: "OrganizationalTransformation.svg",
      text: "Organizational Transformation",
      href:"./services/organizational-transformation"
    },
  ];

  const [tKey] = useTranslation();
  return (
    <div className="title-row">
      <Row className="mt-5 services-section">
        {ourServicesData.map((service, index) => (
          <Col key={index} xl={2} md={2} xs={3}>
            <a href={`${service.href}`}>
            <img
              src={`/assets/img/HomePage/Services/${service.icon}`}
              alt={"services"}
              className="services-icons"
            />
            </a>
            <p className="text-white fw-bold h5 mt-3 services-label text-uppercase">
              {tKey(service.text)}
            </p>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default TitleRowWithIcons;
