import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Header from "../../Header/Header";
import stylesSTR from "../../ValueProposition/StrategySystems/Body/BannerStrategySystems.module.css";
import RowText from "../../ValueProposition/StrategySystems/Body/RowText";
import RowImg from "../../RowImg";
import RowImgText from "./RowImgText";
import CardLeftV from "../../../USPage/Expertise – What We Promise You/Body/CardLeftV";
import CardRightV from "../../../USPage/Expertise – What We Promise You/Body/CardRightV";
import stylesVD from "../../ValueProposition/Capability Development/Body/BannerCapabilityDevelopment.module.css";
import stylesC from "../../ValueProposition/Capability Development/Body/BannerCapabilityDevelopment.module.css";
import styles from "./BannerShifts.module.css";
import styleText from "./../../../../Components/PortfolioPage/ValueProposition/StrategySystems/Body/BannerStrategySystems.module.css";

const BannerShifts = () => {
  const [tKey] = useTranslation();
  useEffect(() => {
    const video = document.getElementById("vidioB");
    video.onplay = function () {
      const loopStartTime = 19;
      video.ontimeupdate = function () {
        if (video.currentTime >= loopStartTime) {
          video.currentTime = 0;
        }
      };
    };
  }, []);
  return (
    <section>
      <Container className={""} dir="">
        <Header
          text="PORTFOLIO"
          classesText={`${stylesSTR.textHeader}`}
          classes={`${stylesSTR.textHeaderChild}  ${styles.textHeaderChild}`}
          textContent="Shifts"
          nameVideo={"Header.m4v"}
        />
        <RowText
          classes={styles.textHeader}
          highlightedTextClasses={styleText.textColor}
          textArray={[
            "Our philosophy and business model are centered around ",
            "making a SHIFT for our clients, ",
            "that can be identified, monitored, measured, and then utilized in their business needs.",
          ]}
        />
        <RowImg
          id="Shifts_1Img"
          classImg={styles.Img}
          nameImg={"Shifts_1.png"}
        />
        <RowText
          classText={styles.text}
          highlightedTextClasses={styleText.textColor}
          textArray={[
            "This ",
            "shift can take different forms, ",
            "not exclusive to financial growth (which is a ",
            "combined outcome of whole-business performance), ",
            "but shifts can happen in different perspectives:",
          ]}
        />

        <RowImgText
          xl={5}
          classes={styles.rowImgText}
          text={"Shifts in (Learning and Growth) of teams and organization"}
          textTow={"Shifts in (Finances) of the organization"}
        />
        <RowImgText
          xl={5}
          classes={styles.rowImgText}
          text={"Shifts in (Internal Processes) of the organization"}
          textTow={"Shifts in (Society and Stakeholders) of the organization"}
        />
        <RowImgText
          xl={10}
          classes={styles.rowImgText}
          text={"Shifts in (Customers/Clients) both internal and external"}
        />
        <RowText
          classText={styles.textMeasuring}
          textArray={[
            "Measuring the shifts can take quantitative and/or qualitative methodologies:",
          ]}
        />
        <CardLeftV
          isPortfolio={true}
          src={"/assets/img/PortfolioPage/"}
          classRow={styles.cardL}
          classtextContent={styles.textContent}
          classImg={styles.imgCard}
          classVid={styles.vidCard}
          classTitle={styles.titleCard}
          nameVid={"ShiftsCard_1.mp4"}
          title={"Key Indicators (KIs)"}
          highlightedTextClasses={styleText.textColor}
          textArray={[
            "as shift can be ",
            "measured by achieving a desired result(s) ",
            "in key performance indicators (KPIs), key risk indicators (KRIs), or key compliance indicators (KCIs)",
          ]}
        />
        <CardRightV
          isPortfolio={true}
          src={"/assets/img/PortfolioPage/"}
          classRow={styles.cardR}
          classtextContent={styles.textContent}
          classVid={styles.vidCard}
          classTitle={styles.titleCard}
          nameVid={"ShiftsCard_2.mp4"}
          title={"Advancement of Levels"}
          highlightedTextClasses={styleText.textColor}
          textArray={[
            "shifts can be measured according to ",
            "(maturity in organizational capability) ",
            "or ",
            "(mastery in personal capacity)",
          ]}
        />
        <CardLeftV
          isPortfolio={true}
          src={"/assets/img/PortfolioPage/"}
          classRow={styles.cardL}
          classtextContent={styles.textContent}
          classImg={styles.imgCard}
          classVid={styles.vidCard}
          classTitle={styles.titleCard}
          nameVid={"ShiftsCard_3.mp4"}
          title={"Perspectives Changes"}
          highlightedTextClasses={styleText.textColor}
          textArray={[
            "as shifts can happen towards a specific topic (e.g., business philosophy or model, new work methodologies/processes/tools, internal or external cases and concerns, future visions and insights), through ",
            "measuring the shifts in values, attitudes, behaviors, and perceptions on different ",
            "levels (personal / team / organizational)",
          ]}
        />
      </Container>
      <video
        id="vidioB"
        className={` ${stylesVD.vidioBody}   `}
        autoPlay={true}
        loop={true}
        muted
      >
        <source src="/assets/video/PortfolioPage/Paralax.m4v" />
      </video>
      <Container>
        <Row xl={6} className={` row justify-content-center `}>
          <Col xl={10} className={`col-auto ${stylesC.TextFooter}`}>
            <p className={` ${stylesC.textF} ${styles.textFS}`}>
              {tKey(
                "Our interest in QUAIM Global is to harness our capabilities, knowledge, and expertise to serve our clients in making SHIFTS in their businesses, environments, and societies."
              )}
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BannerShifts;
