export const IconLinkData = [
  {
    link: "https://www.linkedin.com/company/86323949/admin/feed/posts/",
    imgName: "linkedin.png",
    alt: "linkedin",
  },
  {
    link: "https://www.youtube.com/channel/UCojGMdwrDI-YPi3pjWfI3bg",
    imgName: "youtube.png",
    alt: "youtube",
  },
  {
    link: "https://www.facebook.com/profile.php?id=100083553184839",
    imgName: "facebook.png",
    alt: "facebook",
  },
];

export const IconTextData = [
  {
    imgName: "call.png",
    alt: "call",
    text: "0090-552-461-1119",
  },
  {
    imgName: "sms.png",
    alt: "sms",
    text: "info@quaim.com",
  },
  {
    imgName: "location.png",
    alt: "location",
    text: "GAZIANTEP TURKEY",
  },
];

export const LinkTextData = [
  {
    link: "/us/story",
    text: "US",
  },
  {
    link: "/portfolio/customer-centricity",
    text: "PORTFOLIO",
  },
  {
    link: "/you/projects",
    text: "YOU",
  },
  {
    link: "/services/strategy-and-systems",
    text: "our Services",
  },
  {
    link: "/blog",
    text: "BLOG",
  },
  {
    link: "/together/expert",
    text: "TOGETHER",
  },
];
