import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "./../../Header/Header";
import stylesSTR from "../../../PortfolioPage/ValueProposition/StrategySystems/Body/BannerStrategySystems.module.css";
import styles from "../../You.module.css";
import Gridcell from "../../../Hexagon/Gridcell";
import ProjectsData from "../Data/ProjectsData";
const BannerProjects = () => {
const cells = ProjectsData(0);
return (
    <section>
      <Container className={""} dir="">
        <Header
          text="Projects"
          classesText={`${stylesSTR.textHeader} ${styles.TitleHeader}`}
          classes={stylesSTR.textHeaderChild}
          nameVideo={"Header.m4v"}
        />
        {
          cells.length > 0 
          ? <Row xl={12} className={` row justify-content-center`}>
          <ul id="hexGrid">{cells.map((cell)=> <Gridcell cell_attr ={cell} />)}</ul>
        </Row> 
          : ''
        }
       
        
      </Container>
    </section>
  );
};

export default BannerProjects;
