import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { validationLan } from "../../ValidationLan/ValidationLan";
import { useTranslation } from "react-i18next";
import styles from "./ContactUsPage.module.css";
import FormContact from "./FormContact";
const ContactUsPage = () => {
  const [tKey, i18n] = useTranslation();
  let isArabic = localStorage.getItem("lan") === "AR";
  useEffect(() => {
    validationLan();
    console.log();
  }, [isArabic]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className={`${styles.contactUs}`} id="contactUs">
      <Row
        className={`justify-content-around align-items-center ${styles.headerRow}`}
      >
        <Col xl={5} className={`col-auto ${styles.textHeader}`}>
          <h2 className={`${styles.title}`}>{tKey("Contact Us")}</h2>
          <p className={`${styles.titleP}`}>{tKey("We're here to help")}</p>
        </Col>
      </Row>
      <FormContact/>
    </section>
  );
};

export default ContactUsPage;
