import React from "react";
import { useTranslation } from "react-i18next";
import "./HorizontalLine.css";

const HorizontalLine = (props) => {
  const [tKey, i18n] = useTranslation();
  return (
    <hr
      className={`h-header-line ${
        i18n.language === "en" ? "me-auto" : "ms-auto"
      }`}
    />
  );
};
export default HorizontalLine;
