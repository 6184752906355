import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../Header/Header";
import ColInput from "../../Projects/Body/ColInput";
import stylesSTR from "../../../PortfolioPage/ValueProposition/StrategySystems/Body/BannerStrategySystems.module.css";
import styles from "../../You.module.css";
import CardT from "./CardT";
import styleText from "../../../../Components/PortfolioPage/ValueProposition/StrategySystems/Body/BannerStrategySystems.module.css";
const BannerTestimonials = () => {
  return (
    <section>
      <Container className={""} dir="">
        <Header
          text="Testimony"
          classesText={`${stylesSTR.textHeader} ${styles.TitleHeader}`}
          classes={stylesSTR.textHeaderChild}
          nameVideo={"Header.m4v"}
        />
        <Row xl={6} className="row justify-content-center">
        <CardT
            nameImg={"ahmed-Tawakul karaman.png"}
            textTitle={"Ahmed Alshazli"}
            textTitleContent={"(Project Manager)"}
            highlightedTextClasses={styleText.textColor}
            textArray={[
                "From the first moment of contacting them, I noticed their great interest in meeting the client\'s needs and ensuring his complete satisfaction.",
                "They were always available to answer any queries and provide the necessary technical support and assistance at every stage of the work.",
                "In addition, the quality of the work they provided was outstanding.",
                "My experience with them was a learning and positive experience by all standards.",
                "With what you have said, I would highly recommend QUAIM Global to anyone looking for high quality, impeccable professionalism.",
                "I am sure they will provide the perfect service that exceeds your expectations.",
                "Thank you to everyone at QUAIM Global for their dedication and diligence, and I look forward to doing more business with them in the future.", 
            ]}
          />
          
        </Row>
      </Container>
    </section>
  );
};

export default BannerTestimonials;
