import React from "react";
import { Container } from "react-bootstrap";
import Header from "./../Header/Header";
import RowImg from "./../RowImg";
import stylesSTR from "../../PortfolioPage/ValueProposition/StrategySystems/Body/BannerStrategySystems.module.css";
import stylesP from "./../../PortfolioPage/Customer Centricity/Body/BannerCustomerCentricity.module.css";
import styles from "../../YouPage/You.module.css";

const BannerBlog = () => {
  return (
    <section>
      <Container className={""} dir="">
        <Header
          text="BLOG"
          classesText={`${stylesSTR.textHeader} ${styles.TitleHeader}`}
          classes={stylesSTR.textHeaderChild}
          nameVideo={"Header.mp4"}
        />
      </Container>
    </section>
  );
};

export default BannerBlog;
