import React, { useEffect } from "react";
import BannerBlog from "./Body/BannerBlog";
import { validationLan } from "../../ValidationLan/ValidationLan";

const Blog = () => {
  let isArabic = localStorage.getItem("lan") === "AR";
  useEffect(() => {
    validationLan();
  }, [isArabic]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <BannerBlog />;
};

export default Blog;
