import React ,{ useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import {GOOGLE_CAPTCHA_KEY} from "../../config";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./ContactUsPage.module.css";
import stylesError from "../YouPage/You.module.css";
import stylesButton from "../Button/Button.module.css";
import { useTranslation } from "react-i18next";
import usePostRequest from '../Apis/usePostRequest';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import stylesY from "./../YouPage/You.module.css";
import RowText from "../PortfolioPage/ValueProposition/StrategySystems/Body/RowText";
function FormContact() {
  
  const { register, handleSubmit,reset, formState: { errors } } = useForm();
  const onSubmit = (data) => {
   
    if (isCaptchaVerified) {
       handleSubmit1();
    } else {
      alert('Please verify the reCAPTCHA before submitting the form.');
    
    }
    
  }
  const [tKey] = useTranslation();
  const [data, setData] = useState(null);
  const post = usePostRequest();
  const [isCaptchaVerified, setCaptchaVerified] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email:'',
    subject:'',
    message:''
    });
  // Handle changes in form inputs
    const handleChange = (event) => {
        const { name, value, files } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: files ? files : value // If it's a file input, store the file, otherwise store the value
        }));
    }; 
    const handleSubmit1 = async () => {
      const responseData = await post('/contact', formData);
      setData(responseData);
        reset({
          name: '',
          surname: '',
          email:'',
          subject:'',
          message:''
          });
    };
    const handleCaptchaChange = (value) => {
      // Callback function triggered when reCAPTCHA is verified
      setCaptchaVerified(true);
    };  
  return (
    <Container dir="" className={`${styles.containerContact}`} >
      <ToastContainer  />
       <form 
       //onSubmit={handleSubmit1}
       onSubmit={handleSubmit(onSubmit)} 
       encType = {`multipart/form-data`} 
       >
        <Row className="justify-content-center align-items-center mt-5">
          <Col xl={6} className={`col-auto ${styles.text} text-center`}>
            <h2 className={`${styles.title} ${styles.titleCenter} `}>
              {tKey("Contact Us")}
            </h2>
          </Col>
        </Row>
        <RowText classes={styles.text} textArray={["textOneContactUsPage"]} />
        <Row xl={9} className="row justify-content-center">
        <Col xl={4} className={`col-md-4  row justify-content-center`}>
          <input
           {...register("name", { required: true, maxLength: 250 })} 
            className={`${stylesY.input} ${styles.inputImg_1}`}
            name={`name`}
            type={`name`}
            placeholder={"Name"}
            value={formData.name}
            onChange={handleChange}            
          />
          {errors.name && <p className={`${stylesError.error}`} >Please check the Name </p>}
        </Col>
        <Col xl={4} className={`col-md-4 row justify-content-center`}>
          <input
           {...register("surname", { required: true, maxLength: 250 })}
            className={`${stylesY.input} ${styles.inputImg_2}`}
            name={`surname`}
            type={`surname`}
            placeholder={"surname"}
            value={formData.surname}
            onChange={handleChange}
          />
            {errors.surname && <p className={`${stylesError.error}`} > Please check the Surname </p>}
          
        </Col>
        </Row>
        <Row xl={6} className="row justify-content-center">
      <Col xl={8} className={`${stylesY.colInput} ${styles.colInput} col-md-9`} >
        <input
         {...register("email",{
          required: true, 
          pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ 
          })} 
          className={`${stylesY.input} ${stylesY.inputT}  ${styles.inputImg_3} `}
          name={`email`}
          type={`text`}
          placeholder={"email"}
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <p className={`${stylesError.error}`} > Please Enter Valid Email</p>}
      </Col>
    </Row>
    <Row xl={6} className="row justify-content-center">
      <Col xl={8} className={`${stylesY.colInput} ${styles.colInput} col-md-9`}>
        <input
        {...register("subject",{ required: true})} 
          className={`${stylesY.input} ${stylesY.inputT}  ${styles.inputImg_4} `}
          name={`subject`}
          type={`subject`}
          placeholder={"Subject"}
          value={formData.subject}
          onChange={handleChange}
          />
        {errors.subject && <p className={`${stylesError.error}`} > Please Enter Subject </p>}
      </Col>
    </Row>
    <Row xl={6} className="row justify-content-center">
      <Col xl={8}  className={`col-auto col-md-9 ${stylesY.colInput} ${styles.colInput}`} >
        <textarea
        {...register("message")}
          className={`${stylesY.textArea} ${styles.textArea}`}
          rows={11}
          placeholder={"Message"}
          name={`message`}
          type={`message`}
          value={formData.message}
          onChange={handleChange}
          
        />
      </Col>
    </Row>
    <Row
      xl={6}
      className={`row justify-content-center align-items-center  `}
    >
      <Col xl={3} className=" justify-content-center col-auto text-center  mb-3 ">
      <ReCAPTCHA
        sitekey={GOOGLE_CAPTCHA_KEY}
        onChange={handleCaptchaChange}
      />

      </Col>
    </Row>
    <Row
      xl={6}
      className={`row justify-content-center align-items-center ${stylesButton.Row} `}
    >
      <Col xl={12} className="col-auto text-center">
          <button type={"submit"} className={`${stylesButton.button}`}>{tKey('SUBMIT DATA')}</button>
      </Col>
    </Row>
    </form>
    </Container>
  );
};

export default FormContact;
