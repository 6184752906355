import React from "react";
import { useTranslation } from "react-i18next";
import SiteScroll from "../../../SiteScroll/SiteScroll";
import "./Header.css";

const Header = (props) => {
  const [tKey, i18n] = useTranslation();
  return (
    <div className="sub-services-section">
      <img
        src={`/assets/img/Services/${props.img}`}
        className="sub-services-img-header"
        alt="services"
      />
      <div className="sub-services-content-header">
        <img
          src={`/assets/img/Services/${props.icon}`}
          className="sub-services-img-icon"
          alt="services"
        />
        <h1 className="sub-services-text mt-4">{tKey(props.text)}</h1>
        <SiteScroll />
      </div>
    </div>
  );
};
export default Header;
