import React from "react";
import { useTranslation } from "react-i18next";

const HighlightedText = (props) => {
  const [tKey] = useTranslation();
  return (
    <p className={props.classesText}>
      {props.textArray?.map((text, index) => {
        return (
          <span
            key={index}
            className={index % 2 !== 0 ? props.highlightedTextClasses : ""}
          >
            {tKey(text)}
          </span>
        );
      })}
    </p>
  );
};

export default HighlightedText;
