import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { validationLan } from "../../../ValidationLan/ValidationLan";
import styles from "./Header.module.css";
import SiteScroll from "../../SiteScroll/SiteScroll";
const Header = (props) => {
  let isArabic = localStorage.getItem("lan") === "AR";
  useEffect(() => {
    validationLan();
  }, [isArabic]);
  const [tKey, i18n] = useTranslation();
  return (
    <>
      <video className={styles.bgVid} autoPlay={true} loop={true} muted>
        <source src={`/assets/video/PortfolioPage/${props.nameVideo}`} />
      </video>
      <Row
        xl={6}
        className={` row justify-content-center  ${styles.rowHeader} `}
      >
        <Col xl={5} className={`col-auto ${styles.headerImage}`}>
          <p className={`${props.classesText}`}>{tKey(props.text)}</p>
          <p className={`${props.classes}`}>{tKey(props.textContent)}</p>
        </Col>
      </Row>
    </>
  );
};
export default Header;
