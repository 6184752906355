import React from "react";
import { Container, Row } from "react-bootstrap";
import ServicesContactsSection from "./ServicesContactSection/ServicesContactsSection";
import LeftDirectionService from "./ServicesDetailsSection/LeftDirectionService";
import RightDirectionService from "./ServicesDetailsSection/RightDirectionService";
import "./ServicesSection.css";

const ServicesSection = (props) => {
  return (
    <Container
      fluid
      className={`${props.direction % 2 !== 0 ? "section-bg" : ""} border-btm`}
    >
     
        <RightDirectionService service={props.service} />
    
      <Row className="w-100 justify-content-between my-5 px-7">
        <ServicesContactsSection ContactData={props.service.ContactData} />
      </Row>
    </Container>
  );
};

export default ServicesSection;
