import React from "react";
import { Col, Row } from "react-bootstrap";
import Header from "../../Header/Header";
import CardRightV from "../../Expertise – What We Promise You/Body/CardRightV";
import CardLeftV from "../../Expertise – What We Promise You/Body/CardLeftV";
import RowText from "../../Philosophy -Value-Triangle/Body/RowText";
import stylesBPV from "../../Philosophy -Value-Triangle/Body/BannerPhilosophyValueTriangle.module.css";
import styles from "./BannerHexastagesProcess.module.css";
import styleText from "./../../../../Components/PortfolioPage/ValueProposition/StrategySystems/Body/BannerStrategySystems.module.css";

const BannerHexastagesProcess = () => {
  return (
    <section className={`container-fluid ${styles.PromiseYou}`}>
      <Header
        classesText={styles.classesTextHeader}
        text="Approach Hexagon"
        nameVideo={"HeaderPhilosophu.mp4"}
      />
      <RowText
        xl={6}
        classesCol={stylesBPV.text}
        classes={styles.rowHeader}
        bgColor={stylesBPV.colorL}
        isImage={false}
        nameImg={"Approach Hexagon.png"}
        classesImg={styles.imgApproach}
        textArray={[
          "We Envision Our Approach To Make The Shift With You Through A Hexa-Stage Process That Goes Through The Following:",
        ]}
      />
      <Row xl={6} className={`row justify-content-center `}>
        <Col xl={6} className={`col-auto `}>
          <video
            id="HeagonVID"
            className={`${styles.vidC}`}
            autoPlay={true}
            loop={true}
            muted
            src="/assets/video/USPage/Heagon.mp4"
          ></video>
        </Col>
      </Row>
      <CardLeftV
        classtextContent={styles.textContent}
        classImg={styles.img}
        nameImg={"Approach Hexagon_1.png"}
        title={"Discovery"}
        highlightedTextClasses={styleText.textColor}
        textArray={[
          "Embark on a ",
          "comprehensive understanding of your business; ",
          "as we analyze the needs, drivers, objectives, and context to identify bottlenecks, pain points, and inefficiencies.",
        ]}
      />
      <CardRightV
        classtextContent={styles.textContentRight}
        classImg={styles.img}
        nameImg={"Approach Hexagon_2.png"}
        title={"Design"}
        highlightedTextClasses={styleText.textColor}
        textArray={[
          "Receive a ",
          "comprehensive and operational blueprint ",
          "of organizational development and transformation (ODT), as we design it as an asset that your organization uses to align teams, processes, data, and work environment to ",
          "strategically develop and transform.",
        ]}
      />

      <CardLeftV
        classtextContent={styles.textContent}
        classImg={styles.img}
        nameImg={"Approach Hexagon_3.png"}
        title={"Development"}
        highlightedTextClasses={styleText.textColor}
        textArray={[
          "Obtain a ",
          "closely collaborating team as we work on the development of solutions and relevant assets ",
          "that address identified problems and aspired results.",
        ]}
      />
      <CardRightV
        classtextContent={styles.textContentRight}
        classImg={styles.img}
        nameImg={"Approach Hexagon_4.png"}
        title={"Deployment"}
        highlightedTextClasses={styleText.textColor}
        textArray={[
          "",
          "Observe closely the roll-out and deployment of the solution ",
          "into your organization’s teams, processes, and work environment, as we deliver clear deliverables following a defined timeline, controls, and measures.",
        ]}
      />
      <CardLeftV
        classtextContent={styles.textContent}
        classImg={styles.img}
        nameImg={"Approach Hexagon_5.png"}
        title={"Transformation"}
        highlightedTextClasses={styleText.textColor}
        textArray={[
          "Experience the transformation of your organization’s teams, processes, and work environment into a productive and sustainable change, as ",
          "we continuously monitor change conditions and environment, and mentor change agents and catalysts.",
        ]}
      />
      <CardRightV
        classtextContent={styles.textContentRight}
        classImg={styles.img}
        nameImg={"Approach Hexagon_6.png"}
        title={"Shift"}
        highlightedTextClasses={styleText.textColor}
        textArray={[
          "",
          "Feel and measure a shift ",
          "in business performance, results, and thinking as transformation change fulfills its extent.",
        ]}
      />
    </section>
  );
};

export default BannerHexastagesProcess;
