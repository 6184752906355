import React, { useContext, useEffect } from "react";
import BannerStory from "./Body/BannerStory";
import Starting from "./Body/Starting";
import Ourteam from "./Body/Ourteam";
import { validationLan } from "../../../ValidationLan/ValidationLan";
import currentLanguageContext from "../../Context/Context";

const Story = () => {
  const lang = useContext(currentLanguageContext);
  useEffect(() => {
    validationLan();
    changeImage();
  }, [lang.lan]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const changeImage = () => {
    let STARTINGSTIMG = document.getElementById("STARTINGSTIMG");
    if (lang.lan) {
      STARTINGSTIMG.src = "/assets/img/UsPage/STARTINGST-Ar.jpg";
    } else {
      STARTINGSTIMG.src = "/assets/img/UsPage/STARTINGST.png";
    }
  };

  return (
    <>
      <BannerStory />
      <Starting />
      
    </>
  );
};
export default Story;
