import { useTranslation } from "react-i18next";
import Getdata from "../../Apis/Getdata";
const DomainsData = (id) => {
    const [i18n] = useTranslation();
    const data = (!id)?Getdata("/domains"):Getdata("/domain/"+id);
    let domainsArray = [];
    if(data.length>0 && !id) { 
        data.map((element, index) => {
            domainsArray[index] ={
                'value':element.domain_id,
                'label':(i18n.language === "en") ? element.domain_name_en :element.domain_name_en
            }
        });
    }else domainsArray = data;
    return domainsArray;
}
export default DomainsData;