export const StrategyAndSystemsData = [
  {
    subServiceIcon: "StrategyAndSystems/StrategyAndSystems1.svg",
    subServiceIconAlt: "Business Analysis",
    subServiceName: "Business Analysis",
    subServiceNumber: "01",
    description:
      "Business analysis is about <strong>identifying the requirements, needs, and challenges a business is meant to address </strong>, and formulate a relevant strategy, design, plan, and architecture accordingly, through engagement and collaboration of relevant stakeholders. ",
    upDropDownList: [
      "Business Direction (Mission, Vision, Values)",
      "Business Philosophy (Value Creation / Theory of Change)",
      "Business Model and Understanding",
      "Root Cause Analysis for Issues and Problems",
      "Stakeholders Requirements Analysis ",
   
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "StrategyAndSystems/StrategyAndSystems2.svg",
    subServiceIconAlt: "Business Design",
    subServiceName: "Business Design",
    subServiceNumber: "02",
    description:
      "Business Design is about <strong>evaluating potential solutions and developing a comprehensive business case </strong> outlining the costs, benefits, and risks associated with each option, based on a thorough understanding of business analysis and stakeholders’ requirements. ",
    upDropDownList: [
      "Business Design Criteria",
      "Customer / Client Understanding",
      "Value Proposition Design ",
      "Business Model Development Ideas",
      "Prototype Development",
     
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "StrategyAndSystems/StrategyAndSystems3.svg",
    subServiceIconAlt: "Business Processes Mapping and Design",
    subServiceName: "Business Processes Mapping and Design",
    subServiceNumber: "03",
    description:
      "Business processes mapping and design is about <strong>analyzing and optimizing existing business processes and developing new ones </strong>to enhance efficiency and effectiveness and meet defined business analysis and design. ",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "StrategyAndSystems/StrategyAndSystems4.svg",
    subServiceIconAlt: "Business Planning",
    subServiceName: "Business Planning",
    subServiceNumber: "04",
    description:
      "Business planning is about defining the steps you need to undertake to <strong>move business from an idea to a real-world project, by developing and executing test plans to validate that the proposed business solution</strong> meets the defined requirements and expectations and that a clear plan to roll-out the business solution to market or inside the organization is defined and organized.",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "",
      },
    ],
  },
  {
    subServiceIcon: "StrategyAndSystems/StrategyAndSystems5.svg",
    subServiceIconAlt: "Business Architecture Design",
    subServiceName: "Business Architecture Design",
    subServiceNumber: "05",
    description:
      "Business architecture design is meant to define how the business solution is deployed into your organization according to different perspectives or layers, including business/information/application/technology/spatial/security/social, <strong>to ensure that nothing in the enterprise is running isolated in silos.</strong>",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "StrategyAndSystems/StrategyAndSystems6.svg",
    subServiceIconAlt: "Strategic Analysis",
    subServiceName: "Strategic Analysis",
    subServiceNumber: "06",
    description:"Strategic analysis is about gathering and analyzing data and perceptions that helps an <strong> organization’s leaders decide on priorities and goals, shaping (or shifting) a long-term strategy for the business </strong> using a various set of analytical tools that examine internal and external environment, as well as present conditions and future scenarios. ",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "StrategyAndSystems/StrategyAndSystems7.svg",
    subServiceIconAlt: "Strategy Development",
    subServiceName: "Strategy Development",
    subServiceNumber: "07",
    description:"Strategy development is how an organization determine how it will allocate its resources and get maximum impact from its people and capabilities to achieve its objectives, <strong> it is related to design the organization to best serve the strategy. ",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "StrategyAndSystems/StrategyAndSystems8.svg",
    subServiceIconAlt: "Operational Plan Development",
    subServiceName: "Operational Plan Development",
    subServiceNumber: "08",
    description:"Operational Plan Development is about engaging cross business units and individuals to define objectives, key results, and initiatives to <strong> link strategy to departmental and individual level, to ensure alignment, enhance focus, and inherently promote transparency on a fundamental level.   ",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "StrategyAndSystems/StrategyAndSystems9.svg",
    subServiceIconAlt: "Strategy Implementation Support",
    subServiceName: "Strategy Implementation Support",
    subServiceNumber: "09",
    description:"Strategy implementation support is about providing expert advice on how to best implement strategic and operational plans and initiatives, by reviewing performance and achievements, and <strong> proposing adjustments and solutions to encountered issues that will inevitably appear by time and ensure having an aligned strategy continuously. ",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "StrategyAndSystems/StrategyAndSystems10.svg",
    subServiceIconAlt: "Organizational Governance Development",
    subServiceName: "Organizational Governance Development",
    subServiceNumber: "10",
    description:"Organizational governance development is about the way the organization and its leadership are held accountable; as effective governance is key for the organization to meet its objectives in an uncertain world, and maintaining integrity, by <strong> developing a framework of rules, relationships, systems, and processes that enable authority to be exercised and controlled within an organization.",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "StrategyAndSystems/StrategyAndSystems11.svg",
    subServiceIconAlt: "Organizational Risk Methodology and Plan Development",
    subServiceName: "Organizational Risk Methodology and Plan Development",
    subServiceNumber: "11",
    description:"Organizational risk methodology is about the identification, evaluation, and prioritization of risks following a risk plan that identifies needed <strong>coordination and economical application of resources to minimize, monitor, and control the probability or impact of unfortunate events or to maximize the realization of opportunities.</strong>",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "StrategyAndSystems/StrategyAndSystems12.svg",
    subServiceIconAlt:
      "Organizational Compliance Methodology and Plan Development",
    subServiceName:
      "Organizational Compliance Methodology and Plan Development",
    subServiceNumber: "12",
    description:"Organizational compliance methodology is about assessment and analysis of obligatory and voluntary requirements the organization needs to comply with to ensure that <strong>strategy and objectives achievement is performed within the boundaries of organization’s stakeholders’ requirements and expectations </strong>",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "StrategyAndSystems/StrategyAndSystems13.svg",
    subServiceIconAlt:
      "Organizational Performance Methodology and Plan Development",
    subServiceName:
      "Organizational Performance Methodology and Plan Development",
    subServiceNumber: "13",
    description:"Organizational performance methodology is about translating goals into results, through the comprehensive evaluation whether the <strong>organization is doing things right in the right way on the levels of individual employees, as well as on teams, programs, processes, and the organization as a whole.</strong>",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
  {
    subServiceIcon: "StrategyAndSystems/StrategyAndSystems14.svg",
    subServiceIconAlt:
      "Organizational Quality Methodology and Plan Development",
    subServiceName: "Organizational Quality Methodology and Plan Development",
    subServiceNumber: "14",
    description:"Organizational quality methodology is about developing quality management system that follows clear quality principles, sets quality objectives and plans, executes quality operations, and appraises relevant performance to decide on needed improvements to <strong>fulfill customer and other stakeholders’ requirements, expectations, and aspirations. </strong>",
    upDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    downDropDownList: [
      "Corporate Brand Monitoring",
      "Research / Analysis /Sentiment",
      "Reputation Management Strategy",
      "Technical Development",
      "Content Development",
      "Promotion / Amplification",
    ],
    ContactData: [
      {
        header: "Whatsapp",
        text: "0090-552-461-1119",
        details: "Talk with an expert",
        icon: "WhatsappServices.png",
        alt: "Whatsapp",
        link: "https://wa.me/qr/65EMSJ5LE4QTB1",
      },
      {
        header: "Contact Us",
        text: "Receiving your",
        details: "request as an email",
        icon: "SmsServices.png",
        alt: "Contact Us",
        link: "/contact-us",
      },
      {
        header: "Set a Meeting",
        text: "Schedule a meeting",
        details: "with an expert",
        icon: "MeetingServices.png",
        alt: "Set a Meeting",
        link: "https://calendly.com/quaim-global",
      },
    ],
  },
];
