import React, { useContext, useEffect } from "react";
import BannerShifts from "./Body/BannerShifts";
import { validationLan } from "../../../ValidationLan/ValidationLan";
import currentLanguageContext from "../../Context/Context";

const Shifts = () => {
  const lang = useContext(currentLanguageContext);
  useEffect(() => {
    validationLan();
    changeImage();
  }, [lang.lan]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const changeImage = () => {
    let Shifts_1Img = document.getElementById("Shifts_1Img");

    if (lang.lan) {
      Shifts_1Img.src = "/assets/img/PortfolioPage/Shifts_1-Ar.jpg";
    } else {
      Shifts_1Img.src = "/assets/img/PortfolioPage/Shifts_1.png";
    }
  };
  return (
    <>
      <BannerShifts />
    </>
  );
};

export default Shifts;
