import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import RowImgStory from "./RowImgStory";
import styles from "./Starting.module.css";
import styleText from "./../../../../Components/PortfolioPage/ValueProposition/StrategySystems/Body/BannerStrategySystems.module.css";

const Starting = () => {
  const [tKey, i18n] = useTranslation();
  return (
    <section className={`${styles.StartingSec}`}>
      <Container className={``} dir="">
        <Row xl={6} className={` row justify-content-center `}>
          <Col xl={8} className={`col-auto  `}>
            <h1 className={`${styles.textStarting}`}>{tKey("STARTING")}</h1>
            <p className={`${styles.textStartContaint}`}>
              {tKey("from this perspective, we have ")}
              <span className={styleText.textColor}>
                {tKey("convened on 12/12/2021 to establish QUAIM Global ")}
              </span>
              <span>{tKey("as a company that defines its value by")}</span>
            </p>
          </Col>
        </Row>

        <RowImgStory
          id="STARTINGSTIMG"
          classImg={styles.Img}
          nameImg={"STARTINGST.png"}
        />

        <Row xl={6} className="row justify-content-center">
          <Col xl={10} className={`col-auto ${styles.txFoterstr}`}>
            <p>
              {tKey(
                "WE combined our diversified backgrounds and expertise in (business analysis and development, quality and process improvement, teams’ management from strategy to projects levels, and development of technical solutions) all together into "
              )}
              <span className={styleText.textColor}>
                {tKey(
                  "QUAIM Global to create a unique value that focuses on (Making a Shift for Clients, Teams, and Consultants)"
                )}
              </span>
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Starting;
