import React, { useContext, useEffect } from "react";
import BannerExpertiseWhyQuaim from "./Body/BannerExpertiseWhyQuaim";
import { validationLan } from "../../../ValidationLan/ValidationLan";
import currentLanguageContext from "../../Context/Context";

const ExpertiseWhyQUAIM = () => {
  const lang = useContext(currentLanguageContext);
  useEffect(() => {
    validationLan();
    changeImage();
  }, [lang.lan]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const changeImage = () => {
    let whyQuaimImg = document.getElementById("whyQuaimImg");
    if (lang.lan) {
      whyQuaimImg.style.backgroundImage =
        "url('/assets/img/UsPage/Why%20QUAIM-Ar.png')";
    } else {
      whyQuaimImg.style.backgroundImage =
        "url('/assets/img/UsPage/Why QUAIM.png')";
    }
  };

  return <BannerExpertiseWhyQuaim />;
};
export default ExpertiseWhyQUAIM;
